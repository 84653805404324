<div class="cart_item">
    <div class="cart_item_wrapper">
        <div class="cart_item_heading">
            <i class="ico-rail"></i>&nbsp;
            <span
            >{{ outboundJourney.from }} <span i18n="@@to">to</span>
            {{ outboundJourney.to }}</span>
        </div>
        <img
            *ngIf="outboundFare.identifiers?.isSplit"
            class="split-ticket-logo"
            src="https://images.sabscorp.com/images/enterprise/lightUK/assets/png/split-ticket-logo.png"
            alt="Split Tickets" />
        <strong [ngClass]="outboundFare.identifiers?.isSplit ? 'ticket-name-split' : 'ticket-name'">{{ outboundFare.type }}</strong>
        <app-split-ticket-panel 
            *ngIf="outboundFare.identifiers?.isSplit && outboundFare.identifiers?.fares?.length"
            [showType]="true"
            [type]="outboundFare.type"
            [isSplit]="outboundFare.identifiers?.isSplit" 
            [fares]="outboundFare.identifiers?.fares">
        </app-split-ticket-panel>
        <div class="cart_item_operator">
            <span i18n="@@basketPanelOperatorRail">Operator:&nbsp;</span>
            <strong>{{ getRailOperators(outboundJourney.journeyLegs) }}</strong>
        </div>
        <div class="cart_item_rail_ticket_times">
            <span i18n="@@basketPanelDepartureDateRail">Date:&nbsp;</span>
            <strong>{{ outboundJourney.departDateTime | sabsDate : 2 }}         
               <span>
                {{ outboundJourney.departDateTime | sabsTimePipe : 'timeString'}}
              </span>
            </strong>
        </div>
        <div class="cart_item_view_conditions">
            <a (click)="openRailTicketDetailsDialog(outboundJourney, outboundFare)" i18n="@@railViewConditions" class="view_conditions">View Conditions</a>
        </div>
        <ng-container *ngIf="inboundJourney && inboundFare">
            <div class="cart_item_heading">
                <i class="ico-rail"></i>&nbsp;
                <span
                >{{ inboundJourney.from }} <span i18n="@@to">to</span>
                {{ inboundJourney.to }}</span>
            </div>
            <img
                *ngIf="inboundFare.identifiers?.isSplit"
                class="split-ticket-logo"
                src="https://images.sabscorp.com/images/enterprise/lightUK/assets/png/split-ticket-logo.png"
                alt="Split Tickets" />
            <strong [ngClass]="inboundFare.identifiers?.isSplit ? 'ticket-name-split' : 'ticket-name'">{{ inboundFare.type }}</strong>
            <app-split-ticket-panel 
                *ngIf="inboundFare.identifiers?.isSplit && inboundFare.identifiers?.fares?.length"
                [showType]="true"
                [isSplit]="inboundFare.identifiers?.isSplit" 
                [fares]="inboundFare.identifiers?.fares">
            </app-split-ticket-panel>
            <div class="cart_item_operator">
                <span i18n="@@basketPanelOperatorRail">Operator:&nbsp;</span>
                <strong>{{ getRailOperators(inboundJourney.journeyLegs) }}</strong>
            </div>
            <div class="cart_item_rail_ticket_times">
                <span i18n="@@basketPanelDepartureDateRail">Date:&nbsp;</span>
                <strong>{{ inboundJourney.departDateTime | sabsDate : 2 }}         
                   <span>
                    {{ inboundJourney.departDateTime | sabsTimePipe : 'timeString'}}
                  </span>
                </strong>
            </div>
            <div class="cart_item_view_conditions">
                <a (click)="openRailTicketDetailsDialog(inboundJourney, inboundFare)" i18n="@@railViewConditions" class="view_conditions">View Conditions</a>
            </div>
        </ng-container>
    </div>
</div>