import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { OBTRailJourneyOption } from '@sabstravtech/obtservices/base';
import { RailJourneyLeg, HelperRoutines, ModalOpenerService, UserService, EnterpriseSearchService, ServiceType } from '@sabstravtech/obtservices/angular';
import { DoorToDoorRailResults } from '@sabstravtech/obtservices/base';
@Component({
    templateUrl: './modal-door-to-door-rail-journey-details.component.html',
    styleUrls: ['./modal-door-to-door-rail-journey-details.component.scss']
})
export class ModalDoorToDoorRailJourneyDetailsComponent implements OnInit {
    public result: OBTRailJourneyOption  = null;
    railFare: DoorToDoorRailResults = null;
    selectedFareIndex: number = null;
    selectedFare: string = null;
    public segments: (RailJourneyLeg & { toggleStop: boolean })[];

    constructor(
      public activeModal: NgbActiveModal, 
      private helpers: HelperRoutines, 
      private searchService: EnterpriseSearchService) {}

    ngOnInit() {
      this.segments = this.result.journeyLegs.map(
        (journeyLeg: RailJourneyLeg) : RailJourneyLeg & { toggleStop: boolean } => {
            return { ...journeyLeg, toggleStop: false };
        }
      );
    }

    removeTimeZone(date: string) {
      return this.helpers.sanitiseDateTime(date);
    }

    close() {
        this.activeModal.close({fareIndex: this.selectedFareIndex, selectedFare: this.selectedFare });
    }

    selectNewFare(index: number, fare: string, selected: boolean) {
      if (selected) {
        this.selectedFareIndex = index;
        this.selectedFare = fare;
      } else {
        this.selectedFareIndex = null;
        this.selectedFare = null;
      }  
    }

    async openRailTicketDetailsDialog(railFare: any, index = 0) {
      const ticket = railFare?.fares[index];
      const terms = ticket?.terms;
      const identifiers = ticket?.identifiers;
      const journey = railFare.journey;
      this.searchService.searches[ServiceType.Rail].openRailTicketDetailsDialog(journey, ticket, terms, identifiers, this.railFare.sourceId);
    }
  }
LightningModalTypes.ModalDoorToDoorRailJourneyDetailsComponent.component = ModalDoorToDoorRailJourneyDetailsComponent;
