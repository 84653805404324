import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-split-ticket-panel',
  templateUrl: './split-ticket-panel.component.html',
  styleUrl: './split-ticket-panel.component.scss'
})
export class SplitTicketPanelComponent {
  @Input() fares: any[];
  @Input() isSplit: boolean = false;
}
