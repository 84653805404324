<mat-accordion *ngIf="isSplit && fares?.length">
  <mat-expansion-panel class="split">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span>
          <img
            class="split-ticket"
            src="https://images.sabscorp.com/images/enterprise/lightUK/assets/png/split-ticket.png"
            alt="Split Tickets"
          />
        </span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngFor="let fare of fares">
      <span class="split-fare-title">
        {{ fare.quantity }} x {{ fare.name }}:
        {{ fare.price ? (fare.price | currency : fare.currency) : 'See Outbound' }}
      </span>
    </div>
  </mat-expansion-panel>
</mat-accordion>