import { Component, EventEmitter, Input, Output, OnInit, Inject } from '@angular/core';
import {
  OBTRailJourneyFare,
  RailEnterpriseSearchInterface,
  OBTRailJourneyOption,
  OBTRailFareTypes,
  OBTRailFare,
  RailFareTypes,
  RailDirection,
  ServiceProvider,
  RailBasketItem,
  ClassType
} from '@sabstravtech/obtservices/base';
import {
  ServiceType,
  EnterpriseSearchService,
  WithSubscriptionComponent,
  ModalOpenerService,
  EnterpriseBasketService,
  RailSearchJourneyType,
  RailJourneyFare,
  HelperRoutines,
  DiscountType,
  UserService,
  RailSearchComposition,
  RailClass
} from '@sabstravtech/obtservices/angular';
import { resultIcons } from '../../../../vendor/enum/result-icons.enum';
import { LightningModalTypes } from '../../../../vendor/classes/modal-types.enum';
import { Helpers } from '../../../../vendor/classes/helpers';
import { IRailTicketType } from '../../../../vendor/interfaces/rail-ticket-type-interfaces';
import { DOCUMENT } from '@angular/common';
import { SabsDatePipe } from '../../../../startup/pipes/sabs-date.pipe';
import moment from 'moment';
import { MatButton } from '@angular/material/button';

export enum singleOrReturn{
  single = 'Single',
  return = 'Return'
};

@Component({
  selector:
    'app-rail-results-block[sourceId][journeys][from][to][fareTypes][expandedTickets][direction]',
  templateUrl: './rail-results-block.component.html',
  styleUrls: ['./rail-results-block.component.scss']
})
export class RailResultsBlockComponent extends WithSubscriptionComponent implements OnInit {
  @Input() sourceId: number;
  @Input() journeys: OBTRailJourneyOption[];
  @Input() outboundJourneys: OBTRailJourneyOption[];
  @Input() from: string;
  @Input() to: string;
  @Input() date: string;
  @Input() fareTypes: Record<OBTRailFareTypes, string[]>;
  @Input() expandedTickets: Record<OBTRailFareTypes, Record<string, boolean>>;
  @Input() direction: RailDirection;
  @Input() isExchange: Boolean = false;

  @Output() earlier = new EventEmitter<boolean>();
  @Output() later = new EventEmitter<void>();
  @Output() prevDay = new EventEmitter<void>();
  @Output() nextDay = new EventEmitter<void>();
  @Output() exchangedItemDetail: EventEmitter<RailBasketItem> = new EventEmitter();
  readonly eTicketIcon = 'https://images.sabscorp.com/images/sabs2/icons/eticket.jpg';

  resultItemType = resultIcons;

  ticketTypes: RailFareTypes[];
  RailFareTypes: typeof RailFareTypes = RailFareTypes;
  RailDirection: typeof RailDirection = RailDirection;
  ServiceProvider: typeof ServiceProvider = ServiceProvider;
  searchParams: RailEnterpriseSearchInterface;
  canOverride: boolean = false;
  sourceName: string = '';
  isDateDifferent: boolean = false;
  RailSearchJourneyType: typeof RailSearchJourneyType = RailSearchJourneyType;
  returnValidFares = {};
  newResultsObject: {
    standardSingles: any,
    firstClassSingles: any,
    standardReturns: any,
    firstClassReturns: any
  } = {standardSingles: {},
    firstClassSingles: {},
    standardReturns: {},
    firstClassReturns: {}};
  busyTimes = {};
  datesAfterInitial: boolean[] = [];
  displayStandardSingles = true;
  displayFirstClassSingles = true;
  displayStandardReturns = true;
  displayFirstClassReturns = true;

  constructor(
    private searchService: EnterpriseSearchService,
    private modalService: ModalOpenerService,
    private basketService: EnterpriseBasketService,
    private helpers: HelperRoutines,
    private userService: UserService,
    private sabsDatePipe: SabsDatePipe,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
    this.searchParams = this.searchService.searches[ServiceType.Rail];
    this.getTicketTypes = this.getTicketTypes.bind(this);
    this.ticketTypes = this.searchParams.ticketTypes;
    console.log(this.searchParams);
  }

  ngOnInit(): void {
    this.canOverride = this.userService.canOverride();
    this.sourceName = this.userService.findService(this.sourceId.toString())?.name;
    this.isDateDifferent = this.checkDepartDateTime();
    this.setupNewResultsItem();
    this.setColumnsToDisplay();
  }

  ngOnChanges(changes: any) {
    if (changes.journeys) {
      this.updateNewResultsItem();
      if (this.direction === RailDirection.Inbound && this.searchParams.selectedTicket) {
        this.updateInboundReturns();
      }
      this.setColumnsToDisplay();
    }
  }

  // @Memorise()
  fieldFromObject(
    journey: OBTRailJourneyOption,
    isExpanded: boolean,
    fareTypes: Record<OBTRailFareTypes, string[]>,
    journeyType: OBTRailFareTypes,
    ticketName: string,
    index: number
  ): any {
    if (
      journeyType === RailFareTypes.openReturnJourneyFares &&
      this.direction === RailDirection.Inbound
    ) {
      return null;
    }

    if (isExpanded) {
      const expandedResult = Helpers.fieldFromObjectArray<OBTRailJourneyFare>(journey, [
        journeyType,
        ticketName,
        index
      ]);
      if (expandedResult?.selectable && (!expandedResult?.unavailable || this.canOverride)) {
        this.returnValidFares[journeyType] = fareTypes[journeyType];
      } else if (!expandedResult?.selectable && expandedResult?.unavailable) {
        this.returnValidFares[journeyType] = [];
      }

      if (expandedResult?.removeResult) {
        this.returnValidFares[journeyType] = [];
        return null;
      }
      
      return expandedResult;
    }
    const result = Helpers.fieldFromObjectArray<OBTRailJourneyFare[]>(journey, [
      journeyType,
      ticketName
    ])?.reduce(
      (
        lowest: OBTRailJourneyFare | null,
        ticket: OBTRailJourneyFare
      ): OBTRailJourneyFare | null => {
        if (!ticket) {
          return lowest;
        }
        if (lowest && lowest.price < ticket.price) {
          return lowest;
        } else {
          return ticket || null;
        }
      },
      null
    );

    if (result?.removeResult) {
      this.returnValidFares[journeyType] = [];
      return null;
    }

    if (result?.selectable && (!result?.unavailable || this.canOverride)) {
      this.returnValidFares[journeyType] = fareTypes[journeyType];
    } else if (!result?.selectable && result?.unavailable) {
      this.returnValidFares[journeyType] = [];
    }
    return result;
  }

  async onSelectTicket(
    railJourneyOption: OBTRailJourneyOption,
    railFare: OBTRailJourneyFare,
    section: OBTRailFareTypes
  ) {
    if (this.isExchange) {
      const basketItem = await this.searchParams.selectTicketForExchange(
        this.direction,
        railJourneyOption,
        railFare,
        this.sourceId.toString(),
        section
      );

      this.exchangedItemDetail.emit(basketItem);
    } else {
      if (railFare.selectable) {
        this.searchParams.selectTicket(
          this.direction,
          railJourneyOption,
          railFare,
          this.sourceId.toString(),
          section
        );
        
        if (
          this.searchParams.chosenSearchType === RailSearchJourneyType.SingleJourney ||
          (this.searchParams.chosenSearchType === RailSearchJourneyType.ReturnJourney &&
            this.direction === RailDirection.Inbound)
        ) {
          if (this.searchService.searches[ServiceType.Flight].railFromFlightSearchPerformed) {
            this.searchService.search_objects[ServiceType.Flight].chosen = false;
          }

          if (this.searchParams.selectedTicket) {
            this.basketService.toggleMenu();
          }
        } else {
          // ENT-10365 In relation to user focus, we feel that the focus should remain on the button allowing users o to navigate to the newly presented content.
          //For keyboard users, move focus to the return table
          // const blocks = this.document.getElementsByTagName('app-rail-results-block');
          // const inBoundBlock = blocks[1];
          // const firstInboundTicket = inBoundBlock.getElementsByClassName('ticket_details_link')[0];
          // const returnJourneyFares =  inBoundBlock.getElementsByClassName('selectable_return')[0];
          // if (returnJourneyFares && section === RailFareTypes.returnJourneyFares) {
          //   (returnJourneyFares as HTMLElement).focus();
          // } else {
          //   (firstInboundTicket as HTMLElement).focus();
          // }
        }
      }
    }
  }

  async openRailTicketDetailsDialog(
    journey: OBTRailJourneyOption,
    ticket: OBTRailJourneyFare
  ) {
    if (journey && ticket) {
      const terms = ticket.terms;
      const identifiers = ticket.identifiers;
      this.searchParams.openRailTicketDetailsDialog(journey, ticket, terms, identifiers, this.sourceId.toString());
    }
  }

  getTicketTypes(type: OBTRailFareTypes): IRailTicketType[] {
    if (!this.journeys?.length) {
      return [];
    }

    if (type === RailFareTypes.returnJourneyFares && this.direction === RailDirection.Inbound) {
      const outboundFares = this.sortTickets(this.outboundJourneys, type);
      const inboundFares = this.sortTickets(this.journeys, type);

      // Sort inbound fares to be the same as outbound
      return outboundFares
        .map(x => x.key)
        .reduce((curr, next) => {
          const inboundFare = inboundFares.find(x => x.key === next);
          curr.push(inboundFare);
          return curr;
        }, []);
    }

    if (type !== RailFareTypes.returnJourneyFares) {
      this.journeys.forEach(j => {
        for (const p in j[type]) {
          if (j[type][p].filter(o => o === null).length === j[type][p].length) {
            delete j[type][p];
          }
        }
      });
    }

    return this.sortTickets(this.journeys, type);
  }

  sortTickets(journeys: OBTRailJourneyOption[], type: OBTRailFareTypes) {
    let ticketsObject = {} as IRailTicketType;
    return journeys.reduce(
      (combineJourneys: IRailTicketType[], journey: OBTRailJourneyOption): IRailTicketType[] => {
        const journeyType = this.sortJourneys(ticketsObject, journey[type]);
        combineJourneys = Object.values(journeyType).sort((a, b) => a.price - b.price);
        return combineJourneys;
      },
      []
    );
  }

  sortJourneys(container: IRailTicketType, journey: OBTRailFare): IRailTicketType {
    return Object.entries(journey).reduce(
      (current: IRailTicketType, [key, fares]: [string, OBTRailJourneyFare[]]): IRailTicketType => {
        if (fares?.length) {
          const lowestPrice = this.getLowestFarePrice(fares);
          if (
            container[key] &&
            lowestPrice &&
            (!container[key]?.price || container[key]?.price > lowestPrice)
          ) {
            container[key].price = lowestPrice;
          } else if (!container[key]) {
            container[key] = {
              key: key,
              value: fares.length,
              price: lowestPrice
            };
          }
          current = container;
        }
        return current;
      },
      {} as IRailTicketType
    );
  }

  getLowestFarePrice(fares: OBTRailJourneyFare[]): number {
    return fares.reduce((cheapestprice: number, fare: OBTRailJourneyFare): number => {
      if (fare?.price && (!cheapestprice || cheapestprice > fare?.price)) {
        cheapestprice = fare?.price;
      }
      return cheapestprice;
    }, null);
  }

  isEticketAvailable(
    isExpanded: boolean,
    fareTypes: Record<OBTRailFareTypes, string[]>,
    journeyType: OBTRailFareTypes,
    ticketName: string,
    index: number
  ): any {
    let eticket = false;
    this.journeys.forEach((journey: OBTRailJourneyOption) => {
      const ticket: RailJourneyFare = this.fieldFromObject(
        journey,
        isExpanded,
        fareTypes,
        journeyType,
        ticketName,
        index
      );
      if (ticket) {
        eticket = !!ticket.eTicketAvailable;
      }
    });
    return eticket;
  }

  isLimitedRow(
    isExpanded: boolean,
    fareTypes: Record<OBTRailFareTypes, string[]>,
    journeyType: OBTRailFareTypes,
    ticketName: string,
    index: number
  ): boolean {
    let limited = false;
    this.journeys.forEach((journey: OBTRailJourneyOption) => {
      const ticket: RailJourneyFare = this.fieldFromObject(
        journey,
        isExpanded,
        fareTypes,
        journeyType,
        ticketName,
        index
      );
      if (ticket) {
        limited = !!(ticket.availableSeats && ticket.availableSeats < 9);
      }
    });
    return limited;
  }

  isGroupFare(
    isExpanded: boolean,
    fareTypes: Record<OBTRailFareTypes, string[]>,
    journeyType: OBTRailFareTypes,
    ticketName: string,
    index: number
  ): boolean {
    let found = false;

    this.journeys.forEach((journey: OBTRailJourneyOption) => {
      const ticket: RailJourneyFare = this.fieldFromObject(
        journey,
        isExpanded,
        fareTypes,
        journeyType,
        ticketName,
        index
      );
      if (ticket?.railcardCode) {
        if (this.helpers.isGroupTicket(ticket.railcardCode)) {
          found = true;
        }
      } else if (ticket?.discountInfo?.some(x => x.type === DiscountType.Groupsave)) {
        found = true;
      }
    });

    return found;
  }

  isTrainlineSplitFare(
    isExpanded: boolean,
    fareTypes: Record<OBTRailFareTypes, string[]>,
    journeyType: OBTRailFareTypes,
    ticketName: string,
    index: number
  ): boolean {
    return this.journeys.some((journey: OBTRailJourneyOption) => {
      const ticket: RailJourneyFare = this.fieldFromObject(
        journey,
        isExpanded,
        fareTypes,
        journeyType,
        ticketName,
        index
      );
      return ticket?.identifiers?.isSplit;
    });
  }

  showSplitTooltip(
    isExpanded: boolean,
    fareTypes: Record<OBTRailFareTypes, string[]>,
    journeyType: OBTRailFareTypes,
    ticketName: string,
    index: number
  ): boolean {
    return this.journeys.some((journey: OBTRailJourneyOption) => {
      const ticket: RailJourneyFare = this.fieldFromObject(
        journey,
        isExpanded,
        fareTypes,
        journeyType,
        ticketName,
        index
      );
      return (
        ticket?.identifiers?.isSplit &&
        ticket?.identifiers?.composition.includes(RailSearchComposition.InterchangeSplit)
      );
    });
  }

  checkDepartDateTime(): boolean {
    if (this.journeys[0]?.departDateTime) {
      const formattedDate = this.sabsDatePipe.transform(this.journeys[0].departDateTime, 2, null);
      return this.date !== formattedDate;
    }
    return false;
  }
  getHiddenText(): string {
    return this.searchParams.chosenSearchType === RailSearchJourneyType.ReturnJourney &&
      this.direction === RailDirection.Inbound &&
      this.searchParams.selectedTicket
      ? this.returnValidFares[
          Object.keys(this.returnValidFares).find(fare =>
            this.returnValidFares[fare].includes(this.searchParams.selectedTicket.fare.type)
          )
        ].join(', ') + ' options can now be selected'
      : '';
  }

  getId(ticketType: string, fareTypeIndex: number, index: number): string {
    const directionPrefix = this.direction === RailDirection.Outbound ? 'outbound' : 'inbound';
    const capitalizedTicketType = ticketType.charAt(0).toUpperCase() + ticketType.slice(1);
    return `${directionPrefix}${capitalizedTicketType}${fareTypeIndex + 1}${index + 1}`;
  }

  setupNewResultsItem(): void {
    this.journeys.forEach((journey) => {
      let standardSingles = [];
      let standardReturns = [];
      let firstClassSingles = [];
      let firstClassReturns = [];
      Object.keys(journey.dualSingleJourneyFares).forEach((key) => {
        const arrayOfTickets = journey.dualSingleJourneyFares[key];
        arrayOfTickets.forEach((ticket) => {
          if (ticket?.class === RailClass.Standard) {
            standardSingles.push(ticket);
          } else if (ticket?.class === RailClass.First) {
            firstClassSingles.push(ticket);
          }
        });
      });
      Object.keys(journey.openReturnJourneyFares).forEach((key) => {
        const arrayOfTickets = journey.openReturnJourneyFares[key];
        arrayOfTickets.forEach((ticket) => {
          if (ticket?.class === RailClass.Standard) {
            standardReturns.push(ticket);
          } else if (ticket?.class === RailClass.First) {
            firstClassReturns.push(ticket);
          }
        });
      });
      Object.keys(journey.returnJourneyFares).forEach((key) => {
        const arrayOfTickets = journey.returnJourneyFares[key];
        arrayOfTickets.forEach((ticket) => {
          if (ticket?.class === RailClass.Standard) {
            standardReturns.push(ticket);
          } else if (ticket?.class === RailClass.First) {
            firstClassReturns.push(ticket);
          }
        });
      });
      Object.keys(journey.singleJourneyFares).forEach((key) => {
        const arrayOfTickets = journey.singleJourneyFares[key];
        arrayOfTickets.forEach((ticket) => {
          if (ticket?.class === RailClass.Standard) {
            standardSingles.push(ticket);
          } else if (ticket?.class === RailClass.First) {
            firstClassSingles.push(ticket);
          }
        });
      });
      standardSingles = standardSingles.sort((a,b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0));
      firstClassSingles = firstClassSingles.sort((a,b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0));
      standardReturns = standardReturns.sort((a,b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0));
      firstClassReturns = firstClassReturns.sort((a,b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0));

      this.newResultsObject.standardSingles[journey.journeyHash] = standardSingles[0];
      this.newResultsObject.firstClassSingles[journey.journeyHash] = firstClassSingles[0];
      this.newResultsObject.standardReturns[journey.journeyHash] = standardReturns[0];
      this.newResultsObject.firstClassReturns[journey.journeyHash] = firstClassReturns[0];

      const diff = moment(journey.departDateTime).startOf('day').diff(moment(this.journeys[0].departDateTime).startOf('day'), 'day');
      console.log(`+++ Day Diff: ${diff} +++`);
      this.datesAfterInitial.push(!!diff);
      const departDateTime = moment(journey.departDateTime); //Gets the time but on the current day for checking purposes
      const departTimeToday = moment().clone().hour(departDateTime.hour()).minute(departDateTime.minute()).second(departDateTime.second());
      const sixThirty = moment('06:30','HH:mm');
      const eightThirty = moment('08:30','HH:mm');
      const sixteenThirty = moment('16:30', 'HH:mm');
      const eighteen = moment('18:00','HH:mm');
      if (departTimeToday.isBetween(sixThirty, eightThirty) || departTimeToday.isBetween(sixteenThirty, eighteen)) {
          this.busyTimes[journey.journeyHash] = true;
      }

    })
  }

  journeyTime(startTime: string, endtime: string): string {
      const duration = moment.duration(moment(endtime).diff(moment(startTime)));
      const hours = duration.hours();
      const minutes = duration.minutes();

      const toReturn = [];

      if (hours < 10) {
          toReturn.push('0' + hours);
      } else {
          toReturn.push(hours);
      }
      toReturn.push(':');

      if (minutes < 10) {
          toReturn.push('0' + minutes);
      } else {
          toReturn.push(minutes.toString());
      }

      return toReturn.join(' ');
  }

  getTicketType(  
    journey: OBTRailJourneyOption,
    railFare: OBTRailJourneyFare) {
      let ticketType;
      Object.keys(journey.dualSingleJourneyFares).forEach((key) => {
        const arrayOfTickets = journey.dualSingleJourneyFares[key];
        arrayOfTickets.forEach((ticket) => {
          if (ticket?.fareHash === railFare.fareHash) {
            ticketType = RailFareTypes.dualSingleJourneyFares;
          }
        });
      });
      Object.keys(journey.openReturnJourneyFares).forEach((key) => {
        const arrayOfTickets = journey.openReturnJourneyFares[key];
        arrayOfTickets.forEach((ticket) => {
          if (ticket?.fareHash === railFare.fareHash) {
            ticketType = RailFareTypes.openReturnJourneyFares;
          }
        });
      });
      Object.keys(journey.returnJourneyFares).forEach((key) => {
        const arrayOfTickets = journey.returnJourneyFares[key];
        arrayOfTickets.forEach((ticket) => {
          if (ticket?.fareHash === railFare.fareHash) {
            ticketType = RailFareTypes.returnJourneyFares;
          }
        });
      });
      Object.keys(journey.singleJourneyFares).forEach((key) => {
        const arrayOfTickets = journey.singleJourneyFares[key];
        arrayOfTickets.forEach((ticket) => {
          if (ticket?.fareHash === railFare.fareHash) {
            ticketType = RailFareTypes.singleJourneyFares;
          }
        });
      });
      Object.keys(journey.splitFares).forEach((key) => {
        const arrayOfTickets = journey.splitFares[key];
        arrayOfTickets.forEach((ticket) => {
          if (ticket?.fareHash === railFare.fareHash) {
            ticketType = RailFareTypes.splitFares;
          }
        });
      });
      return ticketType;
  }

  updateNewResultsItem(): void {
    this.journeys.forEach((journey) => {
      Object.keys(journey.dualSingleJourneyFares).forEach((key) => {
        const arrayOfTickets = journey.dualSingleJourneyFares[key];
        arrayOfTickets.forEach((ticket) => {
          if (this.newResultsObject.standardSingles[journey.journeyHash]?.fareHash === ticket?.fareHash) {
            this.newResultsObject.standardSingles[journey.journeyHash] = ticket;
          }
          if (this.newResultsObject.firstClassSingles[journey.journeyHash]?.fareHash === ticket?.fareHash) {
            this.newResultsObject.firstClassSingles[journey.journeyHash] = ticket;
          }
          if (this.newResultsObject.standardReturns[journey.journeyHash]?.fareHash === ticket?.fareHash) {
            this.newResultsObject.standardReturns[journey.journeyHash] = ticket;
          }
          if (this.newResultsObject.firstClassReturns[journey.journeyHash]?.fareHash === ticket?.fareHash) {
            this.newResultsObject.firstClassReturns[journey.journeyHash] = ticket;
          }
        });
      });
      Object.keys(journey.openReturnJourneyFares).forEach((key) => {
        const arrayOfTickets = journey.openReturnJourneyFares[key];
        arrayOfTickets.forEach((ticket) => {
          if (this.newResultsObject.standardSingles[journey.journeyHash]?.fareHash === ticket?.fareHash) {
            this.newResultsObject.standardSingles[journey.journeyHash] = ticket;
          }
          if (this.newResultsObject.firstClassSingles[journey.journeyHash]?.fareHash === ticket?.fareHash) {
            this.newResultsObject.firstClassSingles[journey.journeyHash] = ticket;
          }
          if (this.newResultsObject.standardReturns[journey.journeyHash]?.fareHash === ticket?.fareHash) {
            this.newResultsObject.standardReturns[journey.journeyHash] = ticket;
          }
          if (this.newResultsObject.firstClassReturns[journey.journeyHash]?.fareHash === ticket?.fareHash) {
            this.newResultsObject.firstClassReturns[journey.journeyHash] = ticket;
          }
        });
      });
      Object.keys(journey.returnJourneyFares).forEach((key) => {
        const arrayOfTickets = journey.returnJourneyFares[key];
        arrayOfTickets.forEach((ticket) => {
          if (this.newResultsObject.standardSingles[journey.journeyHash]?.fareHash === ticket?.fareHash) {
            this.newResultsObject.standardSingles[journey.journeyHash] = ticket;
          }
          if (this.newResultsObject.firstClassSingles[journey.journeyHash]?.fareHash === ticket?.fareHash) {
            this.newResultsObject.firstClassSingles[journey.journeyHash] = ticket;
          }
          if (this.newResultsObject.standardReturns[journey.journeyHash]?.fareHash === ticket?.fareHash) {
            this.newResultsObject.standardReturns[journey.journeyHash] = ticket;
          }
          if (this.newResultsObject.firstClassReturns[journey.journeyHash]?.fareHash === ticket?.fareHash) {
            this.newResultsObject.firstClassReturns[journey.journeyHash] = ticket;
          }
        });
      });
      Object.keys(journey.singleJourneyFares).forEach((key) => {
        const arrayOfTickets = journey.singleJourneyFares[key];
        arrayOfTickets.forEach((ticket) => {
          if (this.newResultsObject.standardSingles[journey.journeyHash]?.fareHash === ticket?.fareHash) {
            this.newResultsObject.standardSingles[journey.journeyHash] = ticket;
          }
          if (this.newResultsObject.firstClassSingles[journey.journeyHash]?.fareHash === ticket?.fareHash) {
            this.newResultsObject.firstClassSingles[journey.journeyHash] = ticket;
          }
          if (this.newResultsObject.standardReturns[journey.journeyHash]?.fareHash === ticket?.fareHash) {
            this.newResultsObject.standardReturns[journey.journeyHash] = ticket;
          }
          if (this.newResultsObject.firstClassReturns[journey.journeyHash]?.fareHash === ticket?.fareHash) {
            this.newResultsObject.firstClassReturns[journey.journeyHash] = ticket;
          }
        });
      });
      Object.keys(journey.splitFares).forEach((key) => {
        const arrayOfTickets = journey.splitFares[key];
        arrayOfTickets.forEach((ticket) => {
          if (this.newResultsObject.standardSingles[journey.journeyHash]?.fareHash === ticket?.fareHash) {
            this.newResultsObject.standardSingles[journey.journeyHash] = ticket;
          }
          if (this.newResultsObject.firstClassSingles[journey.journeyHash]?.fareHash === ticket?.fareHash) {
            this.newResultsObject.firstClassSingles[journey.journeyHash] = ticket;
          }
          if (this.newResultsObject.standardReturns[journey.journeyHash]?.fareHash === ticket?.fareHash) {
            this.newResultsObject.standardReturns[journey.journeyHash] = ticket;
          }
          if (this.newResultsObject.firstClassReturns[journey.journeyHash]?.fareHash === ticket?.fareHash) {
            this.newResultsObject.firstClassReturns[journey.journeyHash] = ticket;
          }
        });
      });
    })
  }

  openDetailsModal(journey: OBTRailJourneyOption) {
    this.modalService.open(
        LightningModalTypes.ModalRailJourneyDetailsComponent,
        {},
        { result: journey }
    );
  }

  getDepartCode(journey: OBTRailJourneyOption) {
    return journey.journeyLegs[0].callingPoints[0]?.stationCode;
  }

  getArriveCode(journey: OBTRailJourneyOption) {
    return journey.journeyLegs[journey.journeyLegs.length - 1].callingPoints[journey.journeyLegs[journey.journeyLegs.length - 1].callingPoints.length - 1]?.stationCode;
  }

  async openTicketsOfTheSameType(journey: OBTRailJourneyOption, railFare: OBTRailJourneyFare) {
    let ticketArray = [];
    Object.keys(journey.dualSingleJourneyFares).forEach((key) => {
      const arrayOfTickets = journey.dualSingleJourneyFares[key];
      arrayOfTickets.forEach((ticket) => {
        if (ticket?.singleOrReturn === railFare.singleOrReturn && ticket?.class === railFare.class) {
          ticketArray.push(ticket);
        }
      });
    });
    Object.keys(journey.openReturnJourneyFares).forEach((key) => {
      const arrayOfTickets = journey.openReturnJourneyFares[key];
      arrayOfTickets.forEach((ticket) => {
        if (ticket?.singleOrReturn === railFare.singleOrReturn && ticket?.class === railFare.class) {
          ticketArray.push(ticket);
        }
      });
    });
    Object.keys(journey.returnJourneyFares).forEach((key) => {
      const arrayOfTickets = journey.returnJourneyFares[key];
      arrayOfTickets.forEach((ticket) => {
        if (ticket?.singleOrReturn === railFare.singleOrReturn && ticket?.class === railFare.class) {
          ticketArray.push(ticket);
        }
      });
    });
    Object.keys(journey.singleJourneyFares).forEach((key) => {
      const arrayOfTickets = journey.singleJourneyFares[key];
      arrayOfTickets.forEach((ticket) => {
        if (ticket?.singleOrReturn === railFare.singleOrReturn && ticket?.class === railFare.class) {
          ticketArray.push(ticket);
        }
      });
    });
    Object.keys(journey.splitFares).forEach((key) => {
      const arrayOfTickets = journey.splitFares[key];
      arrayOfTickets.forEach((ticket) => {
        if (ticket?.singleOrReturn === railFare.singleOrReturn && ticket?.class === railFare.class) {
          ticketArray.push(ticket);
        }
      });
    });
    ticketArray = ticketArray.sort((a,b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0));
    await this.modalService.open(
      LightningModalTypes.ModalRailTickets,
      { centered: true },
      {
        tickets: ticketArray,
        sourceName: this.sourceName,
        journey: journey
      }
    ).then((result: OBTRailJourneyFare) => {
      if (result) {
        if (result?.class === RailClass.Standard) {
          if (result?.singleOrReturn === singleOrReturn.single) {
            this.newResultsObject.standardSingles[journey.journeyHash] = result;
            this.onSelectTicket(journey, result, this.getTicketType(journey, result));
          } else if (result?.singleOrReturn === singleOrReturn.return) {
            this.newResultsObject.standardReturns[journey.journeyHash] = result;
            this.onSelectTicket(journey, result, this.getTicketType(journey, result));
          }
        } else if (result?.class === RailClass.First) {
            if (result?.singleOrReturn === singleOrReturn.single) {
              this.newResultsObject.firstClassSingles[journey.journeyHash] = result;
              this.onSelectTicket(journey, result, this.getTicketType(journey, result));
          } else if (result?.singleOrReturn === singleOrReturn.return) {
              this.newResultsObject.firstClassReturns[journey.journeyHash] = result;
              this.onSelectTicket(journey, result, this.getTicketType(journey, result));
          }
        }
      }
    });
  }

  updateInboundReturns(): void {
    this.journeys.forEach((journey) => {
      let standardReturns = [];
      let firstClassReturns = [];
      Object.keys(journey.dualSingleJourneyFares).forEach((key) => {
        const arrayOfTickets = journey.dualSingleJourneyFares[key];
        arrayOfTickets.forEach((ticket) => {
          if (ticket?.class === RailClass.Standard && ticket?.singleOrReturn === singleOrReturn.return && ticket?.selectable) {
            standardReturns.push(ticket);
          } else if (ticket?.class === RailClass.First && ticket?.singleOrReturn === singleOrReturn.return && ticket?.selectable) {
            firstClassReturns.push(ticket);
          }
        });
      });
      Object.keys(journey.openReturnJourneyFares).forEach((key) => {
        const arrayOfTickets = journey.openReturnJourneyFares[key];
        arrayOfTickets.forEach((ticket) => {
          if (ticket?.class === RailClass.Standard && ticket?.singleOrReturn === singleOrReturn.return && ticket?.selectable) {
            standardReturns.push(ticket);
          } else if (ticket?.class === RailClass.First && ticket?.singleOrReturn === singleOrReturn.return && ticket?.selectable) {
            firstClassReturns.push(ticket);
          }
        });
      });
      Object.keys(journey.returnJourneyFares).forEach((key) => {
        const arrayOfTickets = journey.returnJourneyFares[key];
        arrayOfTickets.forEach((ticket) => {
          if (ticket?.class === RailClass.Standard && ticket?.singleOrReturn === singleOrReturn.return && ticket?.selectable) {
            standardReturns.push(ticket);
          } else if (ticket?.class === RailClass.First && ticket?.singleOrReturn === singleOrReturn.return && ticket?.selectable) {
            firstClassReturns.push(ticket);
          }
        });
      });
      Object.keys(journey.singleJourneyFares).forEach((key) => {
        const arrayOfTickets = journey.singleJourneyFares[key];
        arrayOfTickets.forEach((ticket) => {
          if (ticket?.class === RailClass.Standard && ticket?.singleOrReturn === singleOrReturn.return && ticket?.selectable) {
            standardReturns.push(ticket);
          } else if (ticket?.class === RailClass.First && ticket?.singleOrReturn === singleOrReturn.return && ticket?.selectable) {
            firstClassReturns.push(ticket);
          }
        });
      });
      Object.keys(journey.splitFares).forEach((key) => {
        const arrayOfTickets = journey.splitFares[key];
        arrayOfTickets.forEach((ticket) => {
          if (ticket?.class === RailClass.Standard && ticket?.singleOrReturn === singleOrReturn.return && ticket?.selectable) {
            standardReturns.push(ticket);
          } else if (ticket?.class === RailClass.First && ticket?.singleOrReturn === singleOrReturn.return && ticket?.selectable) {
            firstClassReturns.push(ticket);
          }
        });
      });
      standardReturns = standardReturns.sort((a,b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0));
      firstClassReturns = firstClassReturns.sort((a,b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0));
      if (standardReturns.length) {
        this.newResultsObject.standardReturns[journey.journeyHash] = standardReturns[0];
      }
      if (firstClassReturns.length) {
        this.newResultsObject.firstClassReturns[journey.journeyHash] = firstClassReturns[0];
      }
    })
  }

  setColumnsToDisplay(): void {
    let displayStandardSingles = false;
    let displayFirstClassSingles = false;
    let displayStandardReturns = false;
    let displayFirstClassReturns = false;
    this.journeys.forEach((journey) => {
      if (this.newResultsObject.standardSingles[journey.journeyHash]) {
        displayStandardSingles = true;
      }
      if (this.newResultsObject.standardReturns[journey.journeyHash]) {
        displayStandardReturns = true;
      }
      if (this.newResultsObject.firstClassSingles[journey.journeyHash]) {
        displayFirstClassSingles = true;
      }
      if (this.newResultsObject.firstClassReturns[journey.journeyHash]) {
        displayFirstClassReturns = true;
      }
    });
    this.displayStandardSingles = displayStandardSingles;
    this.displayStandardReturns = displayStandardReturns;
    this.displayFirstClassSingles = displayFirstClassSingles;
    this.displayFirstClassReturns = displayFirstClassReturns;
  }
  
  checkForBus(journey: OBTRailJourneyOption) {
    let busLegs = journey.journeyLegs.filter((journeyLeg) => journeyLeg.operator === "Alternative service - BUS");
    return busLegs.length > 0;
  }

}

