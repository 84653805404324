<ng-template #rt let-r="result" let-t="term">
  {{ r.name }}
</ng-template>
<ng-template #railairtemp let-r="result" let-t="term">
  {{ r.destination }}
</ng-template>
<ng-template #cityRt let-r="result" let-t="term">
  <span> {{ r.name }} ({{ r.countryCode }})</span>
</ng-template>
<ng-template #cityRt1 let-r="result" let-t="term">
  <span [innerHTML]="r.name + ' (' + r.countryCode + ')'"></span>
</ng-template>
<ng-container
  *ngIf="{
    results: searchService.searches[ServiceType.Car].results | async,
    isvalid: searchService.searches[ServiceType.Car].isValid | async,
    messages: searchService.searches[ServiceType.Car].messages | async,
    conversionRates: conversionRates$ | async
  } as data">
  <div>
    <h2 class="search_content_titles" i18n="@@cars_searchCriteria">Search Criteria</h2>
    <a href="javascript:void(0)" (click)="skipToResultContent()" class="sr-only sr-only-focusable"
      >Skip to Result Content</a
    >
    <app-invoked-user></app-invoked-user>
  </div>

  <!-- search -->
  <div
    *ngIf="!searchService.search_loading"
    class="car_search_content background-46 flex-column-container">
    <app-search-toggle>
      <a href="javascript:void(0)" (click)="skipToResultContent()" class="sr-only sr-only-focusable"
        >Skip to Result Content</a
      >
      <app-validate-errors [messages]="data.messages"></app-validate-errors>
      <app-car-search></app-car-search>
      <div class="flex-row-container flex-row-container--right">
        <button
          i18n-title
          class="large-button large-button--search"
          title="Start another search"
          (click)="reSearch()"
          i18n="@@carsSearch">
          Search
        </button>
      </div>
    </app-search-toggle>
    <app-risk-alerts [countryCode]="dropoffCountry"></app-risk-alerts>
  </div>
  <!-- end search -->
  <div class="flex-row-container switchrail_container">
    <button
      *ngIf="searchService.searches[ServiceType.Hotel]?.cheapestPrice?.amount"
      (click)="switchToHotel()"
      class="small-button add-hotel-to-search-button"
      id="carresults-button-switchtoHotel">
      <i class="ico-bed" aria-hidden="true"></i>
      <span i18n="@@flightHotelsFrom">Hotels From:</span>&nbsp;
      {{
        searchService.searches[ServiceType.Hotel].cheapestPrice.amount
          | currency : searchService.searches[ServiceType.Hotel].cheapestPrice.currencyCode
      }}
    </button>
  </div>
  <!-- results -->
  <app-no-results
    *ngIf="data.results && data.results.length === 0"
    [type]="searchType"
    extraUpdateText=" - particularly ensuring your times align with vendor opening hours"
    i18n-extraUpdateText>
  </app-no-results>
  <div
    id="car-list"
    class="car_search_results"
    *ngIf="data.results.length > 0 && data.conversionRates">
    <ng-container *ngIf="data.results.length > 1 && !defaultSortingPerformed">
      {{ sortBy(sortOption) }}
    </ng-container>
    <div class="flex-row-container flex-column-mobile">
      <div class="app-left-filters background-60">
        <div class="flex-row-container">
          <h2 i18n="@@carsSearchFilters">Filters</h2>
          <span
            class="filters-clear-top"
            tabindex="0"
            role="button"
            aria-label="Clear filters"
            (click)="clearFilters()"
            (keydown.enter)="clearFilters()"
            (keydown.space)="clearFilters()"
            i18n-aria-label
            i18n="@@carsSearchFiltersClear"
            >Clear</span
          >
        </div>
        <div class="filter_options_content" id="filters">
          <div>
            <div>
              <div class="class_option_list">
                <div class="flex-row-container">
                  <h3 i18n="@@carsClass">Class</h3>
                  <span
                    *ngIf="!showMoreClass && searchParams.carTypeCodes.length > 5"
                    class="car-filters-show-more"
                    (click)="showMoreClass = true; setFocus('toggle_class_button')"
                    (keydown.enter)="showMoreClass = true; setFocus('toggle_class_button')"
                    (keydown.space)="showMoreClass = true; setFocus('toggle_class_button')"
                    tabindex="0"
                    role="button"
                    id="toggle_class_button"
                    i18n-aria-label
                    i18n="@@carsSearchFiltersShowMore"
                    aria-label="Show more class filters">
                    Show more
                    <i class="ico-chevron-down" aria-hidden="true"></i>
                  </span>
                  <span
                    *ngIf="showMoreClass && searchParams.carTypeCodes.length > 5"
                    class="car-filters-show-more"
                    (click)="showMoreClass = false; setFocus('toggle_class_button')"
                    (keydown.enter)="showMoreClass = false; setFocus('toggle_class_button')"
                    (keydown.space)="showMoreClass = false; setFocus('toggle_class_button')"
                    tabindex="0"
                    role="button"
                    id="toggle_class_button"
                    i18n-aria-label
                    i18n="@@carsSearchFiltersShowLess"
                    aria-label="Show less class filters">
                    Show less
                    <i class="ico-chevron-up" aria-hidden="true"></i>
                  </span>
                </div>
                <ul class="search_filter_checkboxes">
                  <mat-checkbox
                    *ngFor="let type of filters.types; let i = index"
                    [ngClass]="{ display_none: i > 4 && !showMoreClass }"
                    color="primary"
                    class="light-primary"
                    (keydown.esc)="closeFiltersOptions()"
                    value="{{ type.value }}"
                    (change)="filterClass(type.value)"
                    [checked]="filterClasses.indexOf(type.value) >= 0"
                    (keydown.enter)="filterClass(type)">
                    {{ type.display | splitCamelCase }}</mat-checkbox
                  >
                </ul>
              </div>
              <div class="class_option_list">
                <h3 i18n="@@carsTransmission">Transmission</h3>
                <ul class="filters-checkbox-list">
                  <mat-checkbox
                    color="primary"
                    class="light-primary"
                    value="Automatic"
                    (keydown.esc)="closeFiltersOptions()"
                    (change)="filterTransmission('auto')"
                    (keydown.enter)="filterTransmission('auto')"
                    [checked]="transmissionString === 'auto'"
                    i18n="@@carsAutomatic">
                    Automatic
                  </mat-checkbox>
                  <mat-checkbox
                    color="primary"
                    class="light-primary"
                    value="Manual"
                    (keydown.esc)="closeFiltersOptions()"
                    (change)="filterTransmission('manual')"
                    (keydown.enter)="filterTransmission('manual')"
                    [checked]="transmissionString === 'manual'"
                    i18n="@@carsManual">
                    Manual
                  </mat-checkbox>
                </ul>
              </div>
              <div class="class_option_list" *ngIf="filters.preference?.length">
                <h3 i18n="@@filterHotelsPreference">Preference</h3>
                <ul class="search_filter_checkboxes">
                  <mat-checkbox
                    *ngFor="let type of filters.preference; let i = index"
                    color="primary"
                    class="light-primary"
                    (keydown.esc)="closeFiltersOptions()"
                    value="{{ type.value }}"
                    (change)="filterPreference(type.value)"
                    [checked]="filterPreferences.indexOf(type.value) >= 0"
                    (keydown.enter)="filterPreference(type)">
                    {{ type.display | splitCamelCase | translate }}</mat-checkbox
                  >
                </ul>
              </div>
              <div class="class_option_list">
                <h3 i18n="@@travelPolicy">Travel Policy</h3>
                <ul class="search_filter_checkboxes">
                  <mat-checkbox
                    *ngFor="let type of filters.travelPolicy; let i = index"
                    color="primary"
                    class="light-primary"
                    (keydown.esc)="closeFiltersOptions()"
                    value="{{ type.value }}"
                    (change)="filterTravelPolicy(type.value)"
                    [checked]="filterTravelPolicies.indexOf(type.value) >= 0"
                    (keydown.enter)="filterTravelPolicy(type)">
                    {{ type.display | splitCamelCase | translate }}</mat-checkbox
                  >
                </ul>
              </div>
              <div class="class_option_list">
                <h3 i18n="@@carsFuel">Fuel</h3>
                <ul class="filters-checkbox-list">
                  <div *ngFor="let fuel of this.carFuelTypes">
                    <mat-checkbox
                      color="primary"
                      class="light-primary"
                      [value]="fuel"
                      (keydown.esc)="closeFiltersOptions()"
                      (change)="filterFuel(fuel)"
                      (keydown.enter)="filterFuel(fuel)"
                      [checked]="transmissionString === fuel">
                      {{ fuel | translate }}
                    </mat-checkbox>
                  </div>
                </ul>
              </div>
            </div>
            <div>
              <div class="class_option_list">
                <h3 id="price_range" i18n="@@carsPriceRange">Price Range</h3>
                <div class="slider_div">
                  <div class="custom-slider">
                    <ngx-slider
                      ariaLabelledBy="price_range"
                      [(value)]="filters.minPrice"
                      [(highValue)]="filters.maxPrice"
                      [options]="sliderOptions()"
                      (keydown.esc)="closeFiltersOptions()"
                      tabindex="-1"
                      aria-hidden="true"></ngx-slider>
                  </div>
                </div>
              </div>
              <div class="class_option_list">
                <h3 i18n="@@carsChainOption" tab-index="0">Chain</h3>
                <ul class="search_filter_checkboxes image_checks">
                  <li
                    *ngFor="let chain of filters.companies; let i = index"
                    (click)="filterChain(chain.display)"
                    (keydown.enter)="filterChain(chain.display)"
                    (keydown.esc)="closeFiltersOptions()"
                    role="button"
                    tabindex="0">
                    <img
                      src="https://images.sabscorp.com/images/carhire/{{ chain.value }}.gif"
                      [ngClass]="{
                        chosen_chain: filterChains.indexOf(chain) >= 0
                      }"
                      alt="Filter by car hire chain {{ chain.display }}" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <button
          class="small-button width-100"
          (click)="clearFilters()"
          i18n="@@carsClear"
          (keydown.esc)="closeFiltersOptions()"
          aria-label="Clear filters">
          Clear
        </button>
      </div>
      <div class="flex-column-container width-100">
        <div class="search_results_header">
          <h2 class="search_results_page_title" id="car_search_title">
            <span i18n="@@carsCarsFor">Cars for</span> {{ originalSearchDestination }}
          </h2>
          <div class="sort_results_actions">
            <!-- Sort and Filtering Options -->
            <!-- Sort -->
            <span class="sortbylabel" i18n="@@carsSortBy">Sort by:</span>
            <button
              aria-controls="sort-options"
              [attr.aria-expanded]="showSortOptions"
              class="toggle_sort background-40"
              (click)="toggleSortOptions()"
              [ngClass]="{ 'toggle_sort_open  background-100': showSortOptions }"
              aria-label="sort">
              <span aria-hidden="true" id="sort-title">{{
                sortOption | titlecase | translate
              }}</span>
            </button>
            <ul
              id="sort-options"
              class="sort_by_options background-80 top-230"
              *ngIf="showSortOptions">
              <li
                id="cheapest-option"
                (click)="sortBy(SortTypesCar.cheapest)"
                (keydown.enter)="sortBy(SortTypesCar.cheapest)"
                (keydown.esc)="toggleSortOptions()"
                [ngClass]="{ active_option: sortOption === SortTypesCar.cheapest }"
                tabindex="0"
                (keydown.arrowdown)="moveArrowDown($event)">
                <span i18n="@@carsCheapest">Cheapest</span>
                <i class="ico-gbp" aria-hidden="true"></i>
              </li>
              <li
                id="expensive-option"
                (click)="sortBy(SortTypesCar.expensive)"
                (keydown.enter)="sortBy(SortTypesCar.expensive)"
                (keydown.esc)="toggleSortOptions()"
                [ngClass]="{ active_option: sortOption === SortTypesCar.expensive }"
                tabindex="0"
                (keydown.arrowdown)="moveArrowDown($event)">
                <span i18n="@@carsExpensive">Expensive</span>
                <i class="ico-gbp" aria-hidden="true"></i>
              </li>
              <li
                id="size-option"
                (click)="sortBy(SortTypesCar.size)"
                (keydown.enter)="sortBy(SortTypesCar.size)"
                (keydown.esc)="toggleSortOptions()"
                [ngClass]="{ active_option: sortOption === SortTypesCar.size }"
                tabindex="0"
                (keydown.arrowdown)="moveArrowDown($event)"
                (keydown.arrowup)="moveArrowUp($event)">
                <span i18n="@@carsSize">Size</span>
                <i class="ico-size" aria-hidden="true"></i>
              </li>
              <li
                id="chain-option"
                (click)="sortBy(SortTypesCar.chain)"
                (keydown.enter)="sortBy(SortTypesCar.chain)"
                (keydown.esc)="toggleSortOptions()"
                [ngClass]="{ active_option: sortOption === SortTypesCar.chain }"
                tabindex="0"
                (keydown.arrowup)="moveArrowUp($event)"
                (keydown.tab)="toggleSortOptions()">
                <span i18n="@@carsChain">Chain</span>
                <i class="ico-car" aria-hidden="true"></i>
              </li>
              <li
                id="preferred-option"
                (click)="sortBy(SortTypesCar.preferred)"
                (keydown.enter)="sortBy(SortTypesCar.preferred)"
                (keydown.esc)="toggleSortOptions()"
                [ngClass]="{ active_option: sortOption === SortTypesCar.preferred }"
                tabindex="0"
                (keydown.arrowup)="moveArrowUp($event)"
                (keydown.tab)="toggleSortOptions()">
                <span i18n="@@carsPref">Preferred</span>
                <i class="ico-heart" aria-hidden="true"></i>
              </li>
              <li
                id="lowest-emissions-option"
                (click)="sortBy(SortTypesCar.lowestEmissions)"
                (keydown.enter)="sortBy(SortTypesCar.lowestEmissions)"
                (keydown.esc)="toggleSortOptions()"
                [ngClass]="{ active_option: sortOption === SortTypesCar.lowestEmissions }"
                tabindex="0"
                (keydown.arrowdown)="moveArrowDown($event)">
                <span i18n="@@Lowest Emissions">Lowest Emissions</span>
                <img
                  class="flightDetails_greenerChoiceLogo"
                  src="https://images.sabscorp.com/images/logo/seedling.svg"
                  alt="Greener Choice" />
              </li>
            </ul>
          </div>
        </div>

        <div class="search_results_items">
          <ng-container *ngFor="let result of data.results; let i = index">
            <div class="car_item" *ngIf="inFilters(result)">
              <!-- individual car item -->
              <app-car-item
                [result]="result"
                [results]="data.results"
                [index]="i"
                [carDetailLoadingStates]="carDetailLoadingStates"
                [preferredCurency]="preferredCurency"
                [conversionRates]="data.conversionRates"></app-car-item>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="no_results_message background-10" *ngIf="data.results.length <= 0" role="status">
        <h2 class="no_results_message_heading">
          <i class="ico-nocar-1 no_results_icon" aria-hidden="true"></i>Oh, no!
        </h2>
        <p>
          <span i18n="@@carsCantFind">We couldn't find any cars that match your criteria. Try</span>
          <strong i18n="@@carsCantFindB">updating your search</strong>.
        </p>
        <p i18n="@@carsContactConsultant">
          If you can't find exactly what you're looking for, contact a consultant.
        </p>
      </div>
    </div>
  </div>
  <!-- end results -->
</ng-container>
<app-car-loading-screen [animated]="false"></app-car-loading-screen>
