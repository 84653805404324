<section
  (mouseleave)="tt2.close()"
  class="container"
  *ngIf="hasBreakfast(room.rateDescription, room.roomDescription, room.policies?.general)">
  <img
    class="toast-icon"
    src="https://images.sabscorp.com/images/Corporate/BedandBreakfast1.png"
    ngbTooltip="Rate may include breakfast"
    i18n-ngbTooltip
    alt="Rate may include breakfast"
    i18n-alt
    triggers="manual"
    #tt2="ngbTooltip"
    (mouseenter)="tt2.open()"
    (focus)="tt2.open()"
    (blur)="tt2.close()"
    tabindex="0" />
</section>