<div cdkTrapFocus aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title">
      <span><span i18n="@@ratesHotelLiveRates">Live Rates for</span> {{ name }}</span>
    </h3>
    <button
      type="button"
      class="close"
      attr.aria-label="Close Live Rates for {{name}} Modal"
      ngbAutoFocus
      (click)="modal.close()">
      <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
  </button>
  </div>
  <div class="modal-body">
    <div class="room_details">
      <div class="rate_source_warning" *ngIf="warnAboutSource(room.rateSource)">
        This is a {{ room.rateSource }} rate, please read the rate rules carefully
      </div>

      <div>
        <h4 class="condition_title" i18n="@@rateInformation">Rate Information:</h4>
        <span>
          {{ room.roomType }}<span *ngIf="room.rateSource">({{ room.rateSource }})</span>:
        </span>
        <span>{{ room.rateDescription }}</span>
        <span *ngFor="let item of room.additional?.details">
          {{ item?.Description }}
        </span>
      </div>

      <div *ngFor="let cond of listOfHotelPolicies">
        <div *ngIf="room.policies[cond] && cond === HotelPolicyEnum.cancellation">
          <h4 class="condition_title room_error">
            <span i18n="@@cancellationPolicy">Cancellation Policy:</span>
            <i
              tabindex="0"
              class="ico-exclaim-1 ico-exclaim-1--strong cancellation-tooltip-icon ng-star-inserted"
              aria-label="Cancellation policy: {{ room.policies[cond] }}">
              <div class="cancellation-tooltip">
                <div class="cancellation-tooltip-arrow"></div>
                <span i18n="@@cancellationPolicy">Cancellation Policy:</span>
                {{ room.policies[cond] }}
              </div>
            </i>
          </h4>
          <span class="room_error">{{ room.policies[cond] }}</span>
        </div>
      </div>

      <div *ngIf="room.roomDescription">
        <h4 class="condition_title" i18n="@@roomInformation">Room Information:</h4>
        <span>{{ room.roomDescription }}</span>
      </div>

      <div *ngFor="let cond of listOfHotelPolicies">
        <div *ngIf="room.policies[cond] && cond === HotelPolicyEnum.general">
          <h4 class="condition_title" i18n="@@generalPolicies">General Policies:</h4>
          <span>{{ room.policies[cond] }}</span>
        </div>
      </div>
      <div *ngFor="let cond of listOfHotelPolicies">
        <div *ngIf="room.policies[cond] && cond === HotelPolicyEnum.disabledAccess">
          <h4 class="condition_title" i18n="@@disabledAccessPolicy">Disabled Access Policy:</h4>
          <span>{{ room.policies[cond] }}</span>
        </div>
      </div>

      <!-- Rate Code start -->
      <div *ngIf="room.rateCode && source === ServiceProvider.Confirma">
        <h4 class="condition_title" i18n="@@rateCode">Rate Code:</h4>
        <span>{{ room.rateCode }}</span>
      </div>
      <div *ngIf="room.additional?.productCode && source === ServiceProvider.SabreCSL">
        <h4 class="condition_title" i18n="@@rateCode">Rate Code:</h4>
        <span>{{ room.additional?.productCode }}</span>
      </div>
      <!-- Rate Code end -->

      <div *ngIf="source">
        <h4 class="condition_title" i18n="@@rateProvider">Rate Provider:</h4>
        <span>{{ source }}</span>
      </div>

      <div *ngFor="let cond of listOfHotelPolicies">
        <div *ngIf="room.policies[cond] && cond === HotelPolicyEnum.guarantee">
          <h4 class="condition_title" i18n="@@guaranteePolicy">Guarantee Policy:</h4>
          <span>{{ room.policies[cond] }}</span>
        </div>
        <div *ngIf="room.policies[cond] && cond === HotelPolicyEnum.deposit">
          <h4 class="condition_title" i18n="@@depositPolicy">Deposit Policy:</h4>
          <span>{{ room.policies[cond] }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="small-button small-button--no"
      i18n="@@fareruleOK"
      (click)="modal.close()"
    >
      Ok
    </button>
  </div>
</div>