<div cdkTrapFocus
     aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title"
        id="modal-header"
        i18n="@@modalRailTicketTypes">Ticket Types</h3>
    <button type="button"
            class="close"
            attr.aria-label="Ticket Types Close"
            ngbAutoFocus
            (click)="activeModal.dismiss()">
      <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
    </button>
  </div>
  <div class="modal-body ticket_list">
    <div class="ticket_item" *ngFor="let ticket of tickets">
      <section class="container" (mouseleave)="tt.close()">
        <mat-radio-group [(ngModel)]="selectedTicket">
          <mat-radio-button aria-label="Select {{ticket.price | currency : ticket.currency}} {{ticket.type}}" 
                            [value]="ticket" 
                            [disabled]="!ticket.selectable || ticket.unavailable"
                            [ngbTooltip]="ticket.unavailable ? 'Fare is unavailable due to being out of policy' : null"
                            i18n-ngbTooltip
                            (focus)="tt.open()"
                            (blur)="tt.close()"
                            (mouseenter)="tt.open()"
                            #tt="ngbTooltip"
                            triggers="manual">
          </mat-radio-button>
        </mat-radio-group>
      </section>
      <div class="ticket_item_details_container">
        <div class="flex-row-container flex-row-container--between">
          <span class="ticket_type">{{ticket.type?.replace(',',', ')}}</span>
          <a (click)="openRailTicketDetailsDialog(ticket)" i18n="@@modalRailViewConditions" class="conditions">View Conditions</a>
        </div>
        <div class="flex-row-container flex-row-container--between price-row">
          <div class="price-and-warning">
            <span class="ticket_price">{{ticket.price | currency : ticket.currency}}</span>
            <app-warning-tags [iconId]="ticket.fareHash" [ticket]="ticket"></app-warning-tags>
          </div>
          <section
            *ngIf="ticket.identifiers?.isSplit"
            class="container">
            <span>
              <img
                class="split-ticket-logo"
                src="https://images.sabscorp.com/images/enterprise/lightUK/assets/png/split-ticket.png"
                alt="Split Tickets"
            /></span>
          </section>
          <section
            (mouseleave)="ttet.close()"
            *ngIf="!!ticket.eTicketAvailable"
            class="container">
            <img
              i18n-ngbTooltip
              ngbTooltip="This is an eTicket that may be SelfPrinted."
              alt="This is an eTicket that may be SelfPrinted."
              [src]="eTicketIcon"
              class="ico-float-left"
              tabindex="0"
              triggers="manual"
              #ttet="ngbTooltip"
              (mouseenter)="ttet.open()"
              (focus)="ttet.open()"
              (blur)="ttet.close()" />
          </section>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button"
            id='button_ok'
            i18n="@@modalRailOk"
            class="small-button"
            (click)="activeModal.close(selectedTicket)">Ok</button>
  </div>
</div>