import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SortType, IHotelResultsConfiguration, HotelSortOrder } from '@sabstravtech/obtservices/base';
import { ServiceType, EnterpriseSearchService, WithSubscriptionComponent, UserService } from '@sabstravtech/obtservices/angular';
import { LightningUserFavorurite } from '../../../../vendor/classes/user-favourite.enum';
import { ThemeService } from '../../../../vendor/services/theme.service';

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss']
})
export class SortComponent extends WithSubscriptionComponent implements OnInit {

  @Input() type: ServiceType;
  showSortOptions: boolean = true;
  public sortOption: string;
  public selected: string = HotelSortOrder.PreferredHotels;
  @Input() sortType: SortType;
  hotelResultsSortConfiguration: string;
  constructor(
    private searchService: EnterpriseSearchService, private userService: UserService,   protected themeService: ThemeService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.type === ServiceType.Hotel) {
      this.hotelResultsSortConfiguration = this.userService.getUserFavoriteObject<IHotelResultsConfiguration>(LightningUserFavorurite.HotelResultsFormConfiguration)?.hotelSortOrder?.hotelSortType || null;
      this.defaultHotelSorting();
      this.subscribe(this.searchService.searches[ServiceType.Hotel].reapplySorting, sort => {
        console.log('+++ Reapply sorting +++');
        this.defaultHotelSorting();

      });
    }
  }

  defaultHotelSorting(): void {
    if (this.hotelResultsSortConfiguration || this.sortType?.title) {
      this.selected = this.hotelResultsSortConfiguration ? this.hotelResultsSortConfiguration : this.sortType?.title;
      switch (this.selected) {
        case HotelSortOrder.Distance:
          this.searchService.searches[ServiceType.Hotel].sortByDistance();
          break;
        case HotelSortOrder.Name:
          this.searchService.searches[ServiceType.Hotel].sortByName();
          break;
        case HotelSortOrder.AgencyPreferred:
        case HotelSortOrder.ClientPreferred:
          this.selected = HotelSortOrder.PreferredHotels;
          this.searchService.searches[ServiceType.Hotel].byUIPreferred(this.hotelResultsSortConfiguration);
          break;
        case HotelSortOrder.Price:
          this.searchService.searches[ServiceType.Hotel].sortByPrice();
          break;
        case HotelSortOrder.PreferredHotels:
        default:
          this.searchService.searches[ServiceType.Hotel].byPreferred();
          break;
      }
    } else {

      const hasClient = this.searchService.searches[ServiceType.Hotel].atLeastOnePreferredClientHotel();
      const hasAgent = this.searchService.searches[ServiceType.Hotel].atLeastOnePreferredAgentHotel();

      if (!hasClient && !hasAgent) {
        this.selected = HotelSortOrder.Distance;
        this.searchService.searches[ServiceType.Hotel].sortByDistance();
      } else {
        this.selected = HotelSortOrder.PreferredHotels;
        this.searchService.searches[ServiceType.Hotel].byPreferred();
      }

    }
    this.searchService.searches[ServiceType.Hotel].ruleSorting();
  }

  /**
 * Array of SortType object that should be visible on dropdown
 *
 * @type {SortType[]}
 * @memberof SortComponent
 */
  @Input() sortTypes: SortType[] = [];

  /**
  * Placeholder text that's used if there is no initial selected option
  *
  * @type {string}
  * @memberof SortComponent
  */
  @Input() placeholderText = 'Sort By...';

  /**
   * Fired when sorting was selected
   *
   * @type {EventEmitter<SortType>}
   * @memberof SortComponent
   */
  @Output() sortChange: EventEmitter<SortType> = new EventEmitter();

  /**
   * Get the selected SortType and emit sortChange
   *
   * @param {string} title
   * @memberof SortComponent
   */
  select(title: string): void {
    const selectedSortType = this.sortTypes.find(sortType => sortType.title === title);
    if (selectedSortType) {
      this.sortChange.emit(selectedSortType);
    }
  }
}
