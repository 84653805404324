<div cdkTrapFocus aria-live="assertive">
    <div class="modal-header">
        <h3 class="modal-title" i18n="@@cheapestFareFinder" id="modal-header">Cheapest Fare Finder</h3>
        <button type="button" class="close" aria-label="Close Cheapest Fare Finder" (click)="activeModal.close()">
            <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container>
            <label i18n="@@cffClass">Class</label>
            <select 
                [(ngModel)]="selectedClass"
                (ngModelChange)="resetSelected()"
                class='select-box select-box--no-icon select-box--border'>
                <option
                    [value]="classSelectors.cheapest"
                    i18n="@@cffAny">Any</option>
                <option
                    [value]="classSelectors.cheapestClass"
                    i18n="@@cffFirstClass">First Class</option>
            </select>
            <div class="flex-row-container flex-column-mobile">
                <div class="flex-column-container flex-grow">
                    <div class="flex-row-container">
                        <span class="outbound_selector">
                            <i class="ico-chevron-left" 
                               (click)="prevDayOutbound()"
                               role="button"
                               tabindex="0"
                               (keydown.enter)="prevDayOutbound()"
                               aria-label="Outbound Previous Day"></i>
                            <span i18n="@@cffOutboundJourney">Outbound Journey</span> - {{outboundJourneys[0]?.departDateTime| sabsDate : 2}}
                            <i class="ico-chevron-right" 
                               (click)="nextDayOutbound()"
                               role="button"
                               tabindex="0"
                               (keydown.enter)="nextDayOutbound()"
                               aria-label="Outbound Next Day"></i>
                        </span>
                        <table class="rail_results_table">
                            <tr *ngFor="let outboundJourney of outboundJourneys; let outboundIndex = index">
                                <th [ngClass]="{ 'selected_outbound': outboundJourney.journeyHash === selectedOutbound?.journeyHash }">
                                    <i  class="ico-chevron-up" 
                                            *ngIf="outboundIndex === 0"
                                            (click)="earlierOrLater(false, false)"
                                            role="button"
                                            tabindex="0"
                                            (keydown.enter)="earlierOrLater(false, false)"
                                            aria-label="Outbound Earlier"></i>
                                    <div>
                                        {{outboundJourney.journeyLegs[0]?.callingPoints[0]?.stationCode}}
                                    </div>
                                    <div>
                                        {{outboundJourney.departDateTime | sabsTimePipe}}
                                    </div>
                                    <i  class="ico-chevron-down" 
                                            *ngIf="outboundIndex === outboundJourneys.length - 1"
                                            (click)="earlierOrLater(false, true)"
                                            role="button"
                                            tabindex="0"
                                            (keydown.enter)="earlierOrLater(false, true)"
                                            aria-label="Outbound Later"></i>
                                </th>
                                <ng-container *ngFor="let inboundJourney of inboundJourneys">
                                    <td [ngClass]="{ 'selected_inbound': inboundJourney.journeyHash === selectedInbound?.journeyHash, 'selected_outbound': outboundJourney.journeyHash === selectedOutbound?.journeyHash }">
                                        <div  class="flex-row-container flex-row-container--center">
                                            <section (mouseleave)="tt.close()" class="container" *ngIf="resultMatrix[outboundJourney.journeyHash][inboundJourney.journeyHash][selectedClass]?.out && resultMatrix[outboundJourney.journeyHash][inboundJourney.journeyHash][selectedClass]?.in" >
                                                <button mat-button
                                                        color="accent"
                                                        [ngbTooltip]="(!resultMatrix[outboundJourney.journeyHash][inboundJourney.journeyHash][selectedClass].out.selectable ||
                                                        !resultMatrix[outboundJourney.journeyHash][inboundJourney.journeyHash][selectedClass].in.selectable ||
                                                        resultMatrix[outboundJourney.journeyHash][inboundJourney.journeyHash][selectedClass].out.unavailable ||
                                                        resultMatrix[outboundJourney.journeyHash][inboundJourney.journeyHash][selectedClass].in.unavailable) ? 'Unavailable' : null"
                                                        triggers="manual"
                                                        #tt="ngbTooltip"
                                                        (focus)="tt.open()"
                                                        (blur)="tt.close()"
                                                        (mouseenter)="tt.open()"
                                                        (click)="selectedOutbound = outboundJourney; selectedInbound = inboundJourney;"
                                                        [ngClass]="{ 'selected-price': inboundJourney.journeyHash === selectedInbound?.journeyHash && outboundJourney.journeyHash === selectedOutbound?.journeyHash,
                                                                'disabled-price': (!resultMatrix[outboundJourney.journeyHash][inboundJourney.journeyHash][selectedClass].out.selectable ||
                                                                !resultMatrix[outboundJourney.journeyHash][inboundJourney.journeyHash][selectedClass].in.selectable ||
                                                                resultMatrix[outboundJourney.journeyHash][inboundJourney.journeyHash][selectedClass].out.unavailable ||
                                                                resultMatrix[outboundJourney.journeyHash][inboundJourney.journeyHash][selectedClass].in.unavailable) }"
                                                        [disabled]="(!resultMatrix[outboundJourney.journeyHash][inboundJourney.journeyHash][selectedClass].out.selectable ||
                                                                    !resultMatrix[outboundJourney.journeyHash][inboundJourney.journeyHash][selectedClass].in.selectable ||
                                                                    resultMatrix[outboundJourney.journeyHash][inboundJourney.journeyHash][selectedClass].out.unavailable ||
                                                                    resultMatrix[outboundJourney.journeyHash][inboundJourney.journeyHash][selectedClass].in.unavailable) ">
                                                    {{resultMatrix[outboundJourney.journeyHash][inboundJourney.journeyHash][selectedClass]?.totalPrice | currency : resultMatrix[outboundJourney.journeyHash][inboundJourney.journeyHash][selectedClass]?.out.currency}}
                                                </button>
                                            </section>
                                            <app-warning-tags  *ngIf="resultMatrix[outboundJourney.journeyHash][inboundJourney.journeyHash][selectedClass]?.out && resultMatrix[outboundJourney.journeyHash][inboundJourney.journeyHash][selectedClass]?.in" [iconId]="resultMatrix[outboundJourney.journeyHash][inboundJourney.journeyHash][selectedClass]?.out.fareHash" [ticket]="resultMatrix[outboundJourney.journeyHash][inboundJourney.journeyHash][selectedClass]?.out"></app-warning-tags>
                                        </div>
                                        <section
                                            *ngIf="resultMatrix[outboundJourney.journeyHash][inboundJourney.journeyHash][selectedClass]?.out.identifiers?.isSplit || 
                                            resultMatrix[outboundJourney.journeyHash][inboundJourney.journeyHash][selectedClass]?.in.identifiers?.isSplit"
                                            class="container flex-row-container flex-row-container--center">
                                            <img
                                                class="split-ticket-logo"
                                                src="https://images.sabscorp.com/images/enterprise/lightUK/assets/png/split-ticket.png"
                                                alt="Split Tickets"
                                            />
                                        </section>
                                    </td>
                                </ng-container>
                            </tr>
                            <tr>
                                <th></th>
                                <ng-container *ngFor="let inboundJourney of inboundJourneys; let inboundIndex = index">
                                    <th [ngClass]="{ 'selected_inbound': inboundJourney.journeyHash === selectedInbound?.journeyHash }">
                                        <i  class="ico-chevron-left" 
                                            *ngIf="inboundIndex === 0"
                                            (click)="earlierOrLater(true, false)"
                                            role="button"
                                            tabindex="0"
                                            (keydown.enter)="earlierOrLater(true, false)"
                                            aria-label="Inbound Earlier"></i>
                                        <div>
                                            {{inboundJourney.journeyLegs[0]?.callingPoints[0]?.stationCode}}
                                        </div>
                                        <div>
                                            {{inboundJourney.departDateTime | sabsTimePipe}}
                                        </div>
                                        <i  class="ico-chevron-right" 
                                            *ngIf="inboundIndex === inboundJourneys.length - 1"
                                            (click)="earlierOrLater(true, true)"
                                            role="button"
                                            tabindex="0"
                                            (keydown.enter)="earlierOrLater(true, true)"
                                            aria-label="Inbound Later"></i>
                                    </th>
                                </ng-container>
                            </tr>
                        </table>
                    </div>
                    <span class="inbound_selector">
                        <i class="ico-chevron-left"  
                           (click)="prevDayInbound()"
                           role="button"
                           tabindex="0"
                           (keydown.enter)="prevDayInbound()"
                           aria-label="Inbound Previous Day"></i>
                        <span i18n="@@cffInboundJourney">Inbound Journey</span> - {{inboundJourneys[0]?.departDateTime| sabsDate : 2}}
                        <i class="ico-chevron-right" 
                           (click)="nextDayInbound()"
                           role="button"
                           tabindex="0"
                           (keydown.enter)="nextDayInbound()"
                           aria-label="Inbound Next Day"></i>
                    </span>
                </div>
                <ng-container *ngIf="selectedOutbound && selectedInbound">
                    <div class="flex-column-container flex-grow">
                        <app-rail-basket-preview class="flex-grow"
                            [outboundJourney]="selectedOutbound"
                            [outboundFare]="resultMatrix[selectedOutbound.journeyHash][selectedInbound.journeyHash][selectedClass]?.out"
                            [inboundJourney]="selectedInbound"
                            [inboundFare]="resultMatrix[selectedOutbound.journeyHash][selectedInbound.journeyHash][selectedClass]?.in"
                            [sourceId]="sourceId"
                        ></app-rail-basket-preview>
                        <div class="add_to_basket_container">
                            <div class="large-button" 
                                 [disabled]="!resultMatrix[selectedOutbound.journeyHash][selectedInbound.journeyHash][selectedClass]?.out || !resultMatrix[selectedOutbound.journeyHash][selectedInbound.journeyHash][selectedClass]?.in" 
                                 (click)="closeAndAddToBasket()"
                                 i18n="@@cffAddToBasket">
                                Add to Basket
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <div class="preload loading-screen-container" *ngIf="loading">
            <div class="loading-screen-box">
                <i class="loading-screen-spinner"></i>
                <span class="loading-screen-text" i18n="@@cffLoading">Loading...</span>
            </div>
        </div>
    </div>
</div>
