import { LOCALE_ID, NgModule } from '@angular/core';
import { AirportCountryPipe } from './pipes/airport-country.pipe';
import { AirportExpressPipe } from './pipes/airport-express.pipe';
import { AirportPostcodePipe } from './pipes/airport-postcode.pipe';
import { BasketSorterPipe } from './pipes/basket-sorter.pipe';
import { CoachOperatorsPipe } from './pipes/coach-operators.pipe';
import { CountryCodePipe } from './pipes/country-code.pipe';
import { CountryRegionPipe } from './pipes/country-region.pipe';
import { FlightCitiesPipe } from './pipes/flight-cities.pipe';
import { FlightNumberPipe } from './pipes/flight-number.pipe';
import { FlightPipe } from './pipes/flight-pipe';

import { FlightRefPipe } from './pipes/flight-ref.pipe';
import { FlightRuleSorterPipe } from './pipes/flight-rule-sorter.pipe';
import { FlightrulePipe } from './pipes/flightrule.pipe';
import { ArraySortPipe } from './pipes/flights-office-list.pipe';
import { MobileReplacementPipe } from './pipes/mobile-replacement.pipe';
import { PolicyChangerPipe } from './pipes/policy-changer.pipe';
import { RailOperatorsPipe } from './pipes/rail-operators.pipe';
import { RailstationAddressPipe } from './pipes/railstation-address.pipe';
import { RemoveDuplicateSplits } from './pipes/remove-duplicate-splits.pipe';
import { ReplaceUnderscorePipe } from './pipes/replace-underscore.pipe';
import { RetexPipe } from './pipes/retex.pipe';
import { SabsDatePipe } from './pipes/sabs-date.pipe';
import { SabsDistancePipe } from './pipes/sabs-distance.pipe';
import { SabsSecondsPipe } from './pipes/sabs-seconds.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SplitCamelCasePipe } from './pipes/split-camel-case.pipe';
import { TimePipe } from './pipes/time-pipe';
import { ToArrayNumbersPipe } from './pipes/to-array-numbers.pipe';
import { ToHtmlPipe } from './pipes/to-html.pipe';
import { UsStatesPipe } from './pipes/us-states.pipe';
import { FilterUniquePipe } from './pipes/filter-unique.pipe';
import { SplitIfCamelCasePipe } from './pipes/split-if-camel-case.pipe';
import { RemoveSpacesPipe } from './pipes/remove-spaces.pipe';
import {
  CommonModule,
  CurrencyPipe,
  HashLocationStrategy,
  LocationStrategy,
  TitleCasePipe
} from '@angular/common';
import { KeyValuePipe } from './pipes/key-value.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { RouterModule } from '@angular/router';
import { ResultsHeaderComponent } from '../results-header/results-header.component';
import { NgPipesModule } from 'ngx-pipes';
import { NgbDateParserFormatter, NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { A11yModule } from '@angular/cdk/a11y';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslatePipe } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { LightningBasketPanelComponent } from './components/basket-panel/basket-panel.component';
import { MapViewComponent } from './components/map-view/map-view.component';
import { BasketFlightComponent } from './components/basket-panel/basket-flight/basket-flight.component';
import { BasketRailComponent } from './components/basket-panel/basket-rail/basket-rail.component';
import { RailCardsComponent } from './components/basket-panel/basket-rail/rail-cards/rail-cards.component';
import { BasketCarHireComponent } from './components/basket-panel/basket-car-hire/basket-car-hire.component';
import { BasketHotelComponent } from './components/basket-panel/basket-hotel/basket-hotel.component';
import { BasketTaxiComponent } from './components/basket-panel/basket-taxi/basket-taxi.component';
import { BasketEurostarComponent } from './components/basket-panel/basket-eurostar/basket-eurostar.component';
import { BasketParkingComponent } from './components/basket-panel/basket-parking/basket-parking.component';
import { BasketLoungeComponent } from './components/basket-panel/basket-lounge/basket-lounge.component';
import { BasketCoachComponent } from './components/basket-panel/basket-coach/basket-coach.component';
import { PriceBreakdownComponent } from './components/basket-panel/price-breakdown/price-breakdown.component';
import { ImageWithErrorComponent } from './components/image-with-error/image-with-error.component';
import { SearchFlightLocationTypeahead } from './components/search-flight-location-typeahead/search-flight-location-typeahead';
import { SearchRailLocationTypeaheadComponent } from './components/search-rail-location-typeahead-component/search-rail-location-typeahead-component.component';
import { SearchCountryComponent } from './components/search-country/search-country';
import { MapContentComponent } from './components/map-content/map-content.component';
import { ValidateDateDirective } from './diriectives/validate-date-directive';
import { InViewDirective } from './diriectives/inview.directive';
import { AirportIataPipe } from './pipes/airport-iata.pipe';
// import { OverRiddenModule } from '../overridden-module/overridden.module';
import { ParkingPriceSortPipe } from './pipes/parking-price-sort.pipe';
import { LoungesPriceSortPipe } from './pipes/lounges-price-sort.pipe';
import { ParkingFiltersPipe } from './pipes/Parking/parking-filters.pipe';
import { BasketDateFormatterPipe } from './pipes/basket-date-formatter.pipe';
import { BaseComponent } from '../vendor/components/base_components/base-componet';
import { BaseSearchComponent } from '../vendor/components/base-search/base-search.component';
import { CovidmapComponent } from '../vendor/components/covidmap/covidmap.component';
import { LightToggleBtnComponent } from './light-toggle-btn/light-toggle-btn.component';
import { LightToggleGroupComponent } from './light-toggle-group/light-toggle-group.component';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { LogonComponent } from './logon/logon.component';
import { MainComponent } from '../vendor/components/main/main.component';
import { PaneComponent } from '../vendor/components/pane/pane.component';
import { CustomNgbDateParserFormatter } from '../vendor/pipes/custom-ngb-date-parser-formatter';
import { DeviceDetector } from '../vendor/services/device-detector.service';
import { RiskAlertsComponent } from '../vendor/components/risk-alerts/risk-alerts.component';
import { CarbonAllowanceComponent } from '../vendor/components/carbon-allowance/carbon-allowance.component';
import { CarbonAllowanceContainerComponent } from '../vendor/components/carbon-allowance-container/carbon-allowance-container.component';
import { MarkdownModule } from 'ngx-markdown';
import { TranslateModule } from '@ngx-translate/core';
import { WCAGFocusHandler } from '../vendor/services/wcag-focus-handler.service';

import { PasswordResetComponent } from './password-reset/password-reset.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RailJourneyDetailsComponent } from './components/basket-panel/basket-rail/rail-journey-details/rail-journey-details.component';
import { ShowSelectButtonPipe } from './pipes/show-select-button.pipe';
import { RecentSearchesComponent } from './components/recent-searches/recent-searches.component';
import { RecentBoltSearchesComponent } from './components/recent-bolt-searches/recent-bolt-searches.component';
import { RecentSearchTravellers } from './components/recent-searches/recent-search-travellers/recent-search-travellers';
import { AirlinePipePipe } from './pipes/airline-pipe.pipe';
import { DepotNamePipe } from './pipes/depot-name.pipe';
import { RailStationNameByIdPipe } from './pipes/rail-station-name-by-id.pipe copy';
import { RailStationNameByCodePipe } from './pipes/rail-station-name-by-code.pipe';
import { ProfileInformationComponent } from '../profile-information/profile-information.component';
import { ProfileInformationProfileComponent } from '../profile-information/profile-information-profile/profile-information-profile.component';
import { ProfileInformationAddressComponent } from '../profile-information/profile-information-address/profile-information-address.component';
import { ProfileInformationMiDefaultComponent } from '../profile-information/profile-information-mi-default/profile-information-mi-default.component';
import { ProfileInformationDocsComponent } from '../profile-information/profile-information-docs/profile-information-docs.component';
import { ProfileInformationLoyaltyComponent } from '../profile-information/profile-information-loyalty/profile-information-loyalty.component';
import { ProfileInformationTravelPreferencesComponent } from '../profile-information/profile-information-travel-preferences/profile-information-travel-preferences.component';
import { IrlJourneyDetailsComponent } from './components/basket-panel/basket-irl/irl-journey-details/irl-journey-details.component';
import { BasketIrlComponent } from './components/basket-panel/basket-irl/basket-irl.component';
import { AnnouncementsComponent } from '../vendor/components/announcements/announcements.component';
import { MiDefaultSelectComponent } from '../profile-information/profile-information-mi-default/mi-default-select/mi-default-select.component';
import { MiDefaultFreeTextValueComponent } from '../profile-information/profile-information-mi-default/mi-default-free-text-value/mi-default-free-text-value.component';
import { BasketInvokedUserComponent } from './components/basket-invoked-user/basket-invoked-user.component';
import { HotelRoomsStatusPipe } from './pipes/hotel-rooms-status.pipe';
import { InvokedUserComponent } from './components/invoked-user/invoked-user.component';
import { switchDistanceFormatPipe } from './pipes/switch-distance-format.pipe';
import { FlightLoadingScreenComponent } from './loading-screen/flight-loading-screen.component';
import { RailLoadingScreenComponent } from './loading-screen/rail-loading-screen.component';
import { ExpressLoadingScreenComponent } from './loading-screen/express-loading-screen.component';
import { IRLLoadingScreenComponent } from './loading-screen/irl-loading-screen.component';
import { HotelLoadingScreenComponent } from './loading-screen/hotel-loading-screen.component';
import { CarLoadingScreenComponent } from './loading-screen/car-loading-screen.component';
import { FerryLoadingScreenComponent } from './loading-screen/ferry-loading-screen.component';
import { ParkingLoadingScreenComponent } from './loading-screen/parking-loading-screen.component';
import { LoungesLoadingScreenComponent } from './loading-screen/lounge-loading-screen.component';
import { EurostarLoadingScreenComponent } from './loading-screen/eurostar-loading-screen.component';
import { TaxiLoadingScreenComponent } from './loading-screen/taxi-loading-screen.component';
import { NgbdDatepickerI18n } from '../vendor/components/base_components/datepicker-i18n/datepicker-i18n';
import { FastTrackLoadingScreenComponent } from './loading-screen/fast-track-loading-screen.component';
import { BasketFastTrackComponent } from './components/basket-panel/basket-fast-track/basket-fast-track.component';
import { ProfileInformationApprovalReassignmentComponent } from '../profile-information/profile-information-approval-reassignment/profile-information-approval-reassignment.component';
import { MemoizePipe } from '../vendor/pipes/memorize-pipe';
import { SpecialFareIconComponent } from '../results-module/components/flights/erm-fare-icon/special-fare-icon.component';
import { ScratchpadModalComponent } from '../itinerary-module/modals/scratchpad-modal/scratchpad-modal.component';
import { CustomRemarksDialogComponent } from '../itinerary-module/modals/custom-remarks-dialog/custom-remarks-dialog.component';
import { ErmTravellerComponent } from './components/erm-traveller/erm-traveller.component';
import { ProfileInformationApprovalComponent } from '../profile-information/app-profile-information-approval/profile-information-approval.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MiDefaultItemComponent } from '../profile-information/profile-information-mi-default/mi-default-item/mi-default-item.component';
import { CheckPassengerAmountPipe } from './pipes/check-passenger-amount.pipe';
import { DatepickerComponent } from '../vendor/components/datepicker/datepicker.component';
import { RemarkFormatPipe } from '../vendor/pipes/remark-format.pipe';
import { PnrImportFormControlPipe } from './pipes/pnr-import-form-control.pipe';
import { GreenerPercentagePipe } from '../vendor/pipes/greener-percentage-pipe';
import { CheckFrequentTravellerPipe } from './pipes/check-frequent-traveller.pipe';
import { ProfileInformationPaymentComponent } from '../profile-information/profile-information-payment/profile-information-payment.component';
import { ApexxNewCardComponent } from '../itinerary-module/components/supplemental/apexx-new-card/apexx-new-card.component';
import { SelectedSearchObjectPipe } from './pipes/selected-search-object.pipe';
import { SubHeaderBarComponent } from '../results-header/sub-header-bar/sub-header-bar.component';
import { OrderMi } from './pipes/order-mi.pipe';
import { ShowDetailsFlightComponent } from '../show-details/show-details-flight/show-details-flight.component';
import { ShowDetailsCarComponent } from '../show-details/show-details-car/show-details-car.component';
import { ShowDetailsHotelComponent } from '../show-details/show-details-hotel/show-details-hotel.component';
import { EncryptPipe } from '../vendor/pipes/encrypt.pipe';
import { EncryptedInputComponent } from './components/encrypted-input/encrypted-input.component';
import { EncryptedDateInputComponent } from './components/encrypted-date-input/encrypted-date-input.component';
import { IrlLegsComponent } from '../results-module/components/irl-results/irl-legs/irl-legs.component';
import { TrainlineEuItemComponent } from '../results-module/components/rail/trainline-eu-results/trainline-eu-item/trainline-eu-item.component';
import { TrainlineEuItemDetailComponent } from '../results-module/components/rail/trainline-eu-results/trainline-eu-item-detail/trainline-eu-item-detail.component';
import { FlightTicketsComponent } from '../results-module/components/flights/flight-tickets/flight-tickets.component';
import { FlightDisplayComponent } from '../results-module/components/flights/flight-display/flight-display.component';
import { FlightHeaderComponent } from '../results-module/components/flights/flight-header/flight-header.component';
import { AncillaryStatusIconComponent } from '../results-module/components/flights/flight-journey-detail/fares-table/ancillary-status-icon/ancillary-status-icon.component';
import { FaresTableComponent } from '../results-module/components/flights/flight-journey-detail/fares-table/fares-table.component';
import { FlightClassComponent } from '../results-module/components/flights/flight-class/flight-class.component';
import { FlightJourneyDetailOld } from '../results-module/components/flights/flight-journey-detail-old/flight-journey-detail';
import { FlightLegsOldComponent } from '../results-module/components/flights/flight-journey-detail-old/flight-legs/flight-legs.component';
import { FlightJourneyDetail } from '../results-module/components/flights/flight-journey-detail/flight-journey-detail';
import { FlightLegsComponent } from '../results-module/components/flights/flight-journey-detail/flight-legs/flight-legs.component';
import { ApartmentLoadingScreenComponent } from './loading-screen/apartment-loading-screen.component';
import { WarningTagsComponent } from '../results-module/components/warning-tags/warning-tags.component';
import { SeasonTicketLoadingScreenComponent } from './loading-screen/season-ticket-loading-screen.component';
import { SeatMapSpacingPipe } from './pipes/seat-map-spacing.pipe';
import { MultiCityFlightsByScheduleComponent } from '../results-module/components/flights/multi-city-flights-by-schedule/multi-city-flights-by-schedule.component';
import { RailResultsBlockComponent } from '../results-module/components/rail/rail-results-block/rail-results-block.component';
import { RailResultsHeaderComponent } from '../results-module/components/rail/rail-results-header/rail-results-header.component';
import { PreferredIconsComponent } from '../results-module/components/preferred-icons/preferred-icons.component';
import { EurostarClassPipe } from './pipes/eurostar-class-pipe.pipe copy';
import { CarItemComponent } from '../results-module/components/cars/car-item/car-item.component';
import { CarVendorNamePipe } from './pipes/car-vendor-name.pipe';
import { BannerComponent } from './components/banner/banner.component';
import { BasketStatusPipe } from './pipes/basket-status.pipe';
import { FareSectionComponent } from '../results-module/components/flights/flight-upsell-dialog/fare-section/fare-section.component';
import { SchemaTitlePipe } from './pipes/schema-title.pipe';
import { MixedCabinTooltipComponent } from '../results-module/components/flights/mixed-cabin-tooltip/mixed-cabin-tooltip.component';
import { CVVCapturingComponent } from '../itinerary-module/components/supplemental/cvv-capturing/cvv-capturing.component';
import { AddTravellersComponent } from '../search-module/components/add-travellers/add-travellers.component';
import { InvokeUserComponent } from '../search-module/components/invoke-user/invoke-user.component';
import { SplitTicketPanelComponent } from '../results-module/components/rail/split-ticket-panel/split-ticket-panel.component'

const PIPES = [
  AirlinePipePipe,
  AirportCountryPipe,
  AirportExpressPipe,
  AirportIataPipe,
  AirportPostcodePipe,
  ArraySortPipe,
  BasketSorterPipe,
  CoachOperatorsPipe,
  CountryCodePipe,
  CountryRegionPipe,
  FlightCitiesPipe,
  FlightNumberPipe,
  FlightPipe,
  FlightRefPipe,
  FlightrulePipe,
  FlightRuleSorterPipe,
  KeysPipe,
  KeyValuePipe,
  MobileReplacementPipe,
  PolicyChangerPipe,
  RailOperatorsPipe,
  RailstationAddressPipe,
  RemoveDuplicateSplits,
  ReplaceUnderscorePipe,
  RetexPipe,
  SabsDatePipe,
  SabsDistancePipe,
  SabsSecondsPipe,
  SafeHtmlPipe,
  SafePipe,
  ShowSelectButtonPipe,
  TimePipe,
  SplitCamelCasePipe,
  ToArrayNumbersPipe,
  ToHtmlPipe,
  UsStatesPipe,
  ParkingPriceSortPipe,
  LoungesPriceSortPipe,
  ParkingFiltersPipe,
  RailStationNameByIdPipe,
  RailStationNameByCodePipe,
  DepotNamePipe,
  FilterUniquePipe,
  SplitIfCamelCasePipe,
  HotelRoomsStatusPipe,
  switchDistanceFormatPipe,
  MemoizePipe,
  RemarkFormatPipe,
  CheckPassengerAmountPipe,
  BasketDateFormatterPipe,
  PnrImportFormControlPipe,
  GreenerPercentagePipe,
  CheckFrequentTravellerPipe,
  SelectedSearchObjectPipe,
  RemoveSpacesPipe,
  OrderMi,
  EncryptPipe,
  SeatMapSpacingPipe,
  EurostarClassPipe,
  CarVendorNamePipe,
  BasketStatusPipe,
  SchemaTitlePipe
];

const ENTRY_COMPONENTS = [
  // Components
  AnnouncementsComponent,
  BaseComponent,
  NgbdDatepickerI18n,
  BaseSearchComponent,
  BasketCarHireComponent,
  BasketCoachComponent,
  BasketEurostarComponent,
  BasketFlightComponent,
  BasketHotelComponent,
  BasketInvokedUserComponent,
  BasketIrlComponent,
  BasketLoungeComponent,
  BasketFastTrackComponent,
  BasketParkingComponent,
  BasketRailComponent,
  RailCardsComponent,
  BasketTaxiComponent,
  CarbonAllowanceComponent,
  CarbonAllowanceContainerComponent,
  CovidmapComponent,
  ImageWithErrorComponent,
  InvokedUserComponent,
  IrlJourneyDetailsComponent,
  LightningBasketPanelComponent,
  LightToggleBtnComponent,
  LightToggleGroupComponent,
  LoadingScreenComponent,
  LogonComponent,
  MainComponent,
  MapContentComponent,
  MapViewComponent,
  MiDefaultFreeTextValueComponent,
  MiDefaultItemComponent,
  MiDefaultSelectComponent,
  PaneComponent,
  PasswordResetComponent,
  PriceBreakdownComponent,
  ProfileInformationAddressComponent,
  ProfileInformationComponent,
  ProfileInformationDocsComponent,
  ProfileInformationPaymentComponent,
  ProfileInformationLoyaltyComponent,
  ProfileInformationApprovalReassignmentComponent,
  ProfileInformationApprovalComponent,
  ProfileInformationMiDefaultComponent,
  ProfileInformationProfileComponent,
  ProfileInformationTravelPreferencesComponent,
  RailJourneyDetailsComponent,
  RecentSearchesComponent,
  RecentBoltSearchesComponent,
  RecentSearchTravellers,
  ResultsHeaderComponent,
  RiskAlertsComponent,
  SearchCountryComponent,
  SearchFlightLocationTypeahead,
  SearchRailLocationTypeaheadComponent,
  FlightLoadingScreenComponent,
  RailLoadingScreenComponent,
  ExpressLoadingScreenComponent,
  IRLLoadingScreenComponent,
  HotelLoadingScreenComponent,
  CarLoadingScreenComponent,
  LoungesLoadingScreenComponent,
  FastTrackLoadingScreenComponent,
  SeasonTicketLoadingScreenComponent,
  ParkingLoadingScreenComponent,
  EurostarLoadingScreenComponent,
  TaxiLoadingScreenComponent,
  FerryLoadingScreenComponent,
  SpecialFareIconComponent,
  ScratchpadModalComponent,
  ErmTravellerComponent,
  DatepickerComponent,
  CustomRemarksDialogComponent,
  ApexxNewCardComponent,
  SubHeaderBarComponent,
  ShowDetailsFlightComponent,
  ShowDetailsCarComponent,
  ShowDetailsHotelComponent,
  EncryptedInputComponent,
  EncryptedDateInputComponent,
  IrlLegsComponent,
  TrainlineEuItemComponent,
  TrainlineEuItemDetailComponent,
  ApartmentLoadingScreenComponent,
  FlightTicketsComponent,
  FlightDisplayComponent,
  FlightHeaderComponent,
  FaresTableComponent,
  AncillaryStatusIconComponent,
  FlightClassComponent,
  FlightJourneyDetail,
  FlightJourneyDetailOld,
  FlightLegsComponent,
  FlightLegsOldComponent,
  MultiCityFlightsByScheduleComponent,
  RailResultsBlockComponent,
  RailResultsHeaderComponent,
  WarningTagsComponent,
  PreferredIconsComponent,
  BannerComponent,
  CarItemComponent,
  FareSectionComponent,
  MixedCabinTooltipComponent,
  CVVCapturingComponent,
  AddTravellersComponent,
  InvokeUserComponent,
  SplitTicketPanelComponent
];

const IMPORT_TO_EXPORT_MODULES = [
  CommonModule,
  FontAwesomeModule,
  FormsModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatInputModule,
  MatMenuModule,
  MatRadioModule,
  MatIconModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatTabsModule,
  NgbModule,
  InfiniteScrollModule,
  NgbPaginationModule,
  NgPipesModule,
  NgxSliderModule,
  ReactiveFormsModule,
  TranslateModule,
  MatProgressSpinnerModule,
  DragDropModule,
  GoogleMapsModule
];

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

const IMPORT_MODULES = [
  ...IMPORT_TO_EXPORT_MODULES,
  A11yModule,
  // HttpClient,
  MarkdownModule.forRoot(),
  RouterModule
];

const PROVIDERS = [
  // Pipes
  AirportCountryPipe,
  AirportExpressPipe,
  AirportPostcodePipe,
  AirportPostcodePipe,
  ArraySortPipe,
  ArraySortPipe,
  BasketSorterPipe,
  BasketSorterPipe,
  CoachOperatorsPipe,
  CountryCodePipe,
  CountryCodePipe,
  CountryRegionPipe,
  CurrencyPipe,
  FlightCitiesPipe,
  FlightNumberPipe,
  FlightPipe,
  FlightRefPipe,
  FlightrulePipe,
  FlightRuleSorterPipe,
  KeysPipe,
  KeyValuePipe,
  LightToggleBtnComponent,
  LightToggleGroupComponent,
  MobileReplacementPipe,
  PolicyChangerPipe,
  RailOperatorsPipe,
  RailstationAddressPipe,
  ResultsHeaderComponent,
  RemoveDuplicateSplits,
  ReplaceUnderscorePipe,
  RetexPipe,
  SabsDatePipe,
  SabsDistancePipe,
  SabsSecondsPipe,
  SafeHtmlPipe,
  SafePipe,
  SplitCamelCasePipe,
  TitleCasePipe,
  ToArrayNumbersPipe,
  ToHtmlPipe,
  TranslatePipe,
  UsStatesPipe,
  ParkingPriceSortPipe,
  LoungesPriceSortPipe,
  ParkingFiltersPipe,
  RailStationNameByIdPipe,
  RailStationNameByCodePipe,
  DepotNamePipe,
  MemoizePipe,
  RemarkFormatPipe,
  BasketDateFormatterPipe,
  PnrImportFormControlPipe,
  GreenerPercentagePipe,
  EncryptPipe,

  // Components
  BaseComponent,
  BaseSearchComponent,
  CovidmapComponent,
  AnnouncementsComponent,
  LoadingScreenComponent,
  LogonComponent,
  MainComponent,
  PaneComponent,
  RiskAlertsComponent,
  ProfileInformationComponent,
  ProfileInformationProfileComponent,
  ProfileInformationAddressComponent,
  ProfileInformationMiDefaultComponent,
  MiDefaultSelectComponent,
  MiDefaultFreeTextValueComponent,
  MiDefaultItemComponent,
  ProfileInformationDocsComponent,
  ProfileInformationLoyaltyComponent,
  ProfileInformationTravelPreferencesComponent,
  ProfileInformationApprovalReassignmentComponent,
  ScratchpadModalComponent,
  CustomRemarksDialogComponent,
  ShowDetailsFlightComponent,
  ShowDetailsCarComponent,
  ShowDetailsHotelComponent,
  EncryptedInputComponent,
  EncryptedDateInputComponent,
  BannerComponent
];

@NgModule({
  declarations: [
    // Components
    ...ENTRY_COMPONENTS,
    PIPES,
    InViewDirective,
    ValidateDateDirective
  ],
  imports: [
    ...IMPORT_MODULES
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyC_I3019IO-Dzdx5Hn-7Hlp1socVWLYeno',
    //   libraries: ['places']
    // })
  ],
  providers: [
    ...PIPES,
    ...PROVIDERS,
    DeviceDetector,
    WCAGFocusHandler,
    { provide: LOCALE_ID, useValue: 'en-EN' },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatter },
    provideHttpClient()
  ],
  exports: [
    ...PROVIDERS,
    ...PIPES,
    ...ENTRY_COMPONENTS,
    IMPORT_TO_EXPORT_MODULES,
    InViewDirective,
    ValidateDateDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class StartupModule { }

