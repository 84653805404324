


import { Component, OnInit, Inject } from '@angular/core';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import {
  EnterpriseSearchService,
  RailAccount,
    RailClass,
    RailTicketQueue,
    ServiceType,
    WithSubscriptionComponent
} from '@sabstravtech/obtservices/angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENT } from '@angular/common';
import { AccountsList, CheapestRailClassPrices, CheapestRailPrices, OBTRailJourneyFare, OBTRailJourneyOption, RailEnterpriseSearchInterface, RailJourneyFareType, RailSearchCriteria, TicketQueueList, TicketType } from '@sabstravtech/obtservices/base';
import _ from 'lodash';
import moment from 'moment';

export enum classSelectors{
  cheapest = 'cheapest',
  cheapestClass = 'cheapestClass'
};

@Component({
    selector: 'app-cheapest-fare-finder-modal.',
    templateUrl: './cheapest-fare-finder-modal.component.html',
    styleUrls: ['./cheapest-fare-finder-modal.component.scss']
})
export class CheapestFareFinderModalComponent extends WithSubscriptionComponent implements OnInit {
  
    searchParams: RailEnterpriseSearchInterface;
    outboundJourneys: OBTRailJourneyOption[] = null;
    inboundJourneys: OBTRailJourneyOption[] = null;
    sourceId: string = '';
    resultMatrix = {};
    selectedOutbound: OBTRailJourneyOption = null;
    selectedInbound: OBTRailJourneyOption = null;
    selectedClass = classSelectors.cheapest;
    loading = false;
    classSelectors = classSelectors;

    constructor(public activeModal: NgbActiveModal, @Inject(DOCUMENT) private document: Document, private searchService: EnterpriseSearchService) {
        super();
        this.searchParams = this.searchService.searches[ServiceType.Rail];
    }

    ngOnInit() {
        this.searchParams.isLoading.subscribe((loading) => {
          if (!loading) {
            this.outboundJourneys = this.searchParams.results.value[0].outbound;
            this.inboundJourneys = this.searchParams.results.value[0].return;
            this.setup();
          }
          this.selectedInbound = null;
          this.selectedOutbound = null;
          this.loading = loading;
        })
    }

    setup(): void {
      this.outboundJourneys.forEach((outboundJourney) => {
            this.resultMatrix[outboundJourney.journeyHash] = {}
            this.inboundJourneys.forEach((inboundJourney) => {
                const outPrices = this.processPrices(
                    [outboundJourney],
                    {
                      cheapestTickets: {
                        dualSingleJourneyFares: { price: 0, journey: null, fare: null },
                        openReturnJourneyFares: { price: 0, journey: null, fare: null },
                        returnJourneyFares: { price: 0, journey: null, fare: null },
                        singleJourneyFares: { price: 0, journey: null, fare: null },
                        splitFares: { price: 0, journey: null, fare: null }
                      },
                      cheapestClassTicket: {
                        standardClassFare: { price: 0, journey: null, fare: null },
                        firstClassFare: { price: 0, journey: null, fare: null },
                        inboundStandardClassFare: { price: 0, journey: null, fare: null },
                        inboundFirstClassFare: { price: 0, journey: null, fare: null }
                      },
                      outboundClassTicket: {
                        standardClassFare: { price: 0, journey: null, fare: null },
                        firstClassFare: { price: 0, journey: null, fare: null },
                        inboundStandardClassFare: { price: 0, journey: null, fare: null },
                        inboundFirstClassFare: { price: 0, journey: null, fare: null }
                      }
                    },
                    false
                  );
            
                  const inPrices = this.processPrices(
                    [inboundJourney],
                    {
                      cheapestTickets: {
                        dualSingleJourneyFares: { price: 0, journey: null, fare: null },
                        openReturnJourneyFares: { price: 0, journey: null, fare: null },
                        returnJourneyFares: { price: 0, journey: null, fare: null },
                        singleJourneyFares: { price: 0, journey: null, fare: null },
                        splitFares: { price: 0, journey: null, fare: null }
                      },
                      cheapestClassTicket: outPrices.cheapestClassTicket,
                      outboundClassTicket: outPrices.cheapestClassTicket
                    },
                    true
                  );

                  let cheapestTickets: {
                    totalPrice: number,
                    out: OBTRailJourneyFare,
                    in: OBTRailJourneyFare
                  };
                  const dualPrice = outPrices.cheapestTickets.dualSingleJourneyFares.price + inPrices.cheapestTickets.dualSingleJourneyFares.price;
                  const returnPrice = outPrices.cheapestTickets.returnJourneyFares.price;
                  const openReturnPrice = outPrices.cheapestTickets.openReturnJourneyFares.price;

                  cheapestTickets = {
                    totalPrice: dualPrice,
                    out: outPrices.cheapestTickets.dualSingleJourneyFares.fare,
                    in: inPrices.cheapestTickets.dualSingleJourneyFares.fare
                  };

                  if (returnPrice > 0 && returnPrice < cheapestTickets.totalPrice) {
                    cheapestTickets = {
                        totalPrice: returnPrice,
                        out: outPrices.cheapestTickets.returnJourneyFares.fare,
                        in: inPrices.cheapestTickets.returnJourneyFares.fare
                    };
                  }

                  if (openReturnPrice > 0 && openReturnPrice < cheapestTickets.totalPrice) {
                    cheapestTickets = {
                        totalPrice: openReturnPrice,
                        out: outPrices.cheapestTickets.openReturnJourneyFares.fare,
                        in: inPrices.cheapestTickets.openReturnJourneyFares.fare
                    };
                  }

                  let cheapestClassTickets: {
                    totalPrice: number,
                    out: OBTRailJourneyFare,
                    in: OBTRailJourneyFare
                  };

                  const firstClassIsReturn = outPrices.cheapestClassTicket.firstClassFare.fare?.singleOrReturn === "Return";

                  if (firstClassIsReturn) {
                    cheapestClassTickets = {
                        totalPrice: outPrices.cheapestClassTicket.firstClassFare.fare?.price,
                        out: outPrices.cheapestClassTicket.firstClassFare.fare,
                        in: inPrices.cheapestClassTicket.firstClassFare.fare
                    }
                  } else {
                    cheapestClassTickets = {
                        totalPrice: outPrices.cheapestClassTicket.firstClassFare.fare?.price + inPrices.cheapestClassTicket.firstClassFare.fare?.price,
                        out: outPrices.cheapestClassTicket.firstClassFare.fare,
                        in: inPrices.cheapestClassTicket.firstClassFare.fare
                    }
                  }


                  this.resultMatrix[outboundJourney.journeyHash][inboundJourney.journeyHash] = {
                    cheapest: cheapestTickets,
                    cheapestClass: cheapestClassTickets
                  };

            })
        })
    }

    processPrices(
        journeys: OBTRailJourneyOption[],
        prices: {
          cheapestTickets: CheapestRailPrices;
          cheapestClassTicket: CheapestRailClassPrices;
          outboundClassTicket: CheapestRailClassPrices;
        },
        isReturn: boolean
      ): {
        cheapestTickets: CheapestRailPrices;
        cheapestClassTicket: CheapestRailClassPrices;
        outboundClassTicket: CheapestRailClassPrices;
      } {
        const min = (
          current: {
            price: number;
            journey: OBTRailJourneyOption;
            fare: OBTRailJourneyFare;
          },
          fares: OBTRailJourneyFare[],
          journey: OBTRailJourneyOption
        ): {
          price: number;
          journey: OBTRailJourneyOption;
          fare: OBTRailJourneyFare;
        } => {
          if (!fares || fares.length === 0) {
            return current;
          }
    
          let cheapest = current;
    
          for (let i = 0; i < fares.length; i++) {
            const next = fares[i].price;
            if ((cheapest.price > next && next > 0) || !cheapest.price) {
              cheapest = { price: next, journey, fare: fares[i] };
            }
          }
    
          return cheapest;
        };
    
        return journeys.reduce(
          (
            prices1: {
              cheapestTickets: CheapestRailPrices;
              cheapestClassTicket: CheapestRailClassPrices;
              outboundClassTicket: CheapestRailClassPrices;
            },
            journey: OBTRailJourneyOption
          ): {
            cheapestTickets: CheapestRailPrices;
            cheapestClassTicket: CheapestRailClassPrices;
            outboundClassTicket: CheapestRailClassPrices;
          } => {
            const sort = (first: OBTRailJourneyFare, second: OBTRailJourneyFare): number => {
              return first.price - second.price;
            };
            const dualSingleStandardJourneys = _.flatten(
              Object.values(journey.dualSingleJourneyFares)
            ).filter(journey1 => journey1 && journey1.class === RailClass.Standard);
    
            const dualSingleFirstJourneys = _.flatten(
              Object.values(journey.dualSingleJourneyFares)
            ).filter((journey1: OBTRailJourneyFare) => journey1 && journey1.class === RailClass.First);
            const openReturnStandardJourneys = _.flatten(
              Object.values(journey.openReturnJourneyFares)
            ).filter(
              (journey1: OBTRailJourneyFare) => journey1 && journey1.class === RailClass.Standard
            );
            const openReturnFirstJourneys = _.flatten(
              Object.values(journey.openReturnJourneyFares)
            ).filter((journey1: OBTRailJourneyFare) => journey1 && journey1.class === RailClass.First);
            const returnStandardJourneys = _.flatten(Object.values(journey.returnJourneyFares)).filter(
              (journey1: OBTRailJourneyFare) => journey1 && journey1.class === RailClass.Standard
            );
            const returnFirstJourneys = _.flatten(Object.values(journey.returnJourneyFares)).filter(
              (journey1: OBTRailJourneyFare) => journey1 && journey1.class === RailClass.First
            );
            const singleStandardJourneys = _.flatten(Object.values(journey.singleJourneyFares)).filter(
              (journey1: OBTRailJourneyFare) => journey1 && journey1.class === RailClass.Standard
            );
            const singleFirstJourneys = _.flatten(Object.values(journey.singleJourneyFares)).filter(
              (journey1: OBTRailJourneyFare) => journey1 && journey1.class === RailClass.First
            );
    
            const noDualStandard: OBTRailJourneyFare[] = [
              ...openReturnStandardJourneys,
              ...returnStandardJourneys,
              ...singleStandardJourneys
            ];
            const allStandard: OBTRailJourneyFare[] = [
              ...dualSingleStandardJourneys,
              ...noDualStandard
            ].sort(sort);
            const noDualFirst: OBTRailJourneyFare[] = [
              ...openReturnFirstJourneys,
              ...returnFirstJourneys,
              ...singleFirstJourneys
            ];
            const allFirst: OBTRailJourneyFare[] = [...dualSingleFirstJourneys, ...noDualFirst].sort(
              sort
            );
            if (!isReturn) {
              noDualStandard.sort(sort);
              allFirst.sort(sort);
            }
    
            prices1.cheapestTickets.dualSingleJourneyFares = min(
              prices1.cheapestTickets.dualSingleJourneyFares,
              _.flatten(Object.values(journey.dualSingleJourneyFares)).filter(
                (journey1: OBTRailJourneyFare) => !!journey1
              ),
              journey
            );
            prices1.cheapestTickets.openReturnJourneyFares = min(
              prices1.cheapestTickets.openReturnJourneyFares,
              _.flatten(Object.values(journey.openReturnJourneyFares)).filter(
                (journey1: OBTRailJourneyFare) => !!journey1
              ),
              journey
            );
    
            prices1.cheapestTickets.returnJourneyFares = min(
              prices1.cheapestTickets.returnJourneyFares,
              _.flatten(Object.values(journey.returnJourneyFares)).filter(
                (journey1: OBTRailJourneyFare) => !!journey1
              ),
              journey
            );
            prices1.cheapestTickets.splitFares = min(
              prices1.cheapestTickets.splitFares,
              _.flatten(Object.values(journey.splitFares)).filter(
                (journey1: OBTRailJourneyFare) => !!journey1
              ),
              journey
            );
            if (isReturn) {
              prices1.cheapestClassTicket.inboundStandardClassFare = min(
                prices1.cheapestClassTicket.inboundStandardClassFare,
                allStandard,
                journey
              );
              prices1.cheapestClassTicket.inboundFirstClassFare = min(
                prices1.cheapestClassTicket.inboundFirstClassFare,
                allFirst,
                journey
              );
    
              prices1.outboundClassTicket.inboundStandardClassFare = min(
                prices1.outboundClassTicket.inboundStandardClassFare,
                noDualStandard,
                journey
              );
              prices1.outboundClassTicket.inboundFirstClassFare = min(
                prices1.outboundClassTicket.inboundFirstClassFare,
                noDualFirst,
                journey
              );
              const isStandardClassSingle =
                prices1.cheapestClassTicket.standardClassFare?.fare?.singleOrReturn ===
                RailJourneyFareType.Single;
              if (isStandardClassSingle) {
                const inboundPrice = min(
                  prices1.cheapestClassTicket.inboundStandardClassFare,
                  dualSingleStandardJourneys,
                  journey
                );
                const outboundTotalPrice = prices1.cheapestClassTicket.standardClassFare.price;
                if (outboundTotalPrice > prices1.outboundClassTicket.standardClassFare.price) {
                  prices1.cheapestClassTicket.standardClassFare =
                    prices1.outboundClassTicket.standardClassFare;
                } else if (
                  inboundPrice.price > prices1.cheapestClassTicket.inboundStandardClassFare.price
                ) {
                  prices1.cheapestClassTicket.inboundStandardClassFare = inboundPrice;
                }
              }
    
              const isFirstClassSingle =
                prices1.cheapestClassTicket.firstClassFare?.fare?.singleOrReturn ===
                RailJourneyFareType.Single;
              if (isFirstClassSingle) {
                const inboundPrice = min(
                  prices1.cheapestClassTicket.inboundFirstClassFare,
                  dualSingleFirstJourneys,
                  journey
                );
                const totalPrice = prices1.cheapestClassTicket.firstClassFare.price;
                if (totalPrice > prices1.outboundClassTicket.firstClassFare.price) {
                  prices1.cheapestClassTicket.firstClassFare =
                    prices1.outboundClassTicket.firstClassFare;
                } else if (
                  inboundPrice.price > prices1.cheapestClassTicket.inboundFirstClassFare.price
                ) {
                  prices1.cheapestClassTicket.inboundFirstClassFare = inboundPrice;
                }
              }
            } else {
              prices1.cheapestClassTicket.standardClassFare = min(
                prices1.cheapestClassTicket.standardClassFare,
                allStandard,
                journey
              );
              prices1.cheapestClassTicket.firstClassFare = min(
                prices1.cheapestClassTicket.firstClassFare,
                allFirst,
                journey
              );
    
              prices1.outboundClassTicket.standardClassFare = min(
                prices1.outboundClassTicket.standardClassFare,
                noDualStandard,
                journey
              );
              prices1.outboundClassTicket.firstClassFare = min(
                prices1.outboundClassTicket.firstClassFare,
                noDualFirst,
                journey
              );
            }
            return prices1;
          },
          prices
        );
      }

      closeAndAddToBasket(): void {
        const outbound = {
          journey: this.selectedOutbound,
          fare: this.resultMatrix[this.selectedOutbound.journeyHash][this.selectedInbound.journeyHash][this.selectedClass]?.out
        };
        const inbound = {
          journey: this.selectedInbound,
          fare: this.resultMatrix[this.selectedOutbound.journeyHash][this.selectedInbound.journeyHash][this.selectedClass]?.in
        };
        this.activeModal.close([outbound, inbound]);
      }

      prevDayInbound() {
        this.searchParams.inBoundDateTime = this.searchParams.inBoundDateTime.subtract(1, 'day');
        if (this.searchParams.isValid.value) {
          this.searchService.search_objects[ServiceType.Rail].chosen = true;
          this.searchService.determineHighestSearchPriority();
          this.searchService.startSearches();
        }
      }

      prevDayOutbound() {
        this.searchParams.outBoundDateTime = this.searchParams.outBoundDateTime.subtract(1, 'day');
        if (this.searchParams.isValid.value) {
          this.searchService.search_objects[ServiceType.Rail].chosen = true;
          this.searchService.determineHighestSearchPriority();
          this.searchService.startSearches();
        }
      }

      nextDayInbound() {
        this.searchParams.inBoundDateTime = this.searchParams.inBoundDateTime.add(1, 'day');
        if (this.searchParams.isValid.value) {
          this.searchService.search_objects[ServiceType.Rail].chosen = true;
          this.searchService.determineHighestSearchPriority();
          this.searchService.startSearches();
        }
      }

      nextDayOutbound() {
        this.searchParams.outBoundDateTime = this.searchParams.outBoundDateTime.add(1, 'day');
        if (this.searchParams.isValid.value) {
          this.searchService.search_objects[ServiceType.Rail].chosen = true;
          this.searchService.determineHighestSearchPriority();
          this.searchService.startSearches();
        }
      }

      earlierOrLater(inbound: boolean, later: boolean) {
        if (inbound) {
            this.searchParams.returnCriteria = RailSearchCriteria.Depart;
            if (!later) {
              // Get the time diff by comparing the depature time between the first journey and the last journey in the current result
              const earliestJourney = this.inboundJourneys[0].departDateTime;
              const latestJourney = this.inboundJourneys[this.inboundJourneys.length - 1].departDateTime;
              const diff = (earliestJourney !== latestJourney) ? Math.max(moment(latestJourney).diff(moment(earliestJourney), 'minutes'), 45) : 90;
              // If only one journey is in the current result, default to going back 90 minutes. 
              // Otherwise, use the minimum value of 45 minutes or the time between the first and last journey.
              this.searchParams.inBoundDateTime = moment(earliestJourney)
                .subtract(diff, 'minutes')
                .subtract(15, 'minutes'); // Subtract 15 more minutes to get rid the earliest journey in the previous result
            } else {
              // Add 10 more minutes to get rid the latest journey in the previous result
              this.searchParams.inBoundDateTime = moment(this.inboundJourneys[this.inboundJourneys.length - 1].departDateTime).add(10, 'minutes');
            }
          } else {
            this.searchParams.outboundCriteria = RailSearchCriteria.Depart;
            if (!later) {
              // Get the time diff by comparing the depature time between the first journey and the last journey in the current result
              const earliestJourney = this.outboundJourneys[0].departDateTime;
              const latestJourney = this.outboundJourneys[this.outboundJourneys.length - 1].departDateTime;
              const diff = (earliestJourney !== latestJourney) ? Math.max(moment(latestJourney).diff(moment(earliestJourney), 'minutes'), 45) : 90;
              // If only one journey is in the current result, default to going back 90 minutes. 
              // Otherwise, use the minimum value of 45 minutes or the time between the first and last journey.
              this.searchParams.outBoundDateTime = moment(earliestJourney)
                .subtract(diff, 'minutes')
                .subtract(15, 'minutes'); // Subtract 15 more minutes to get rid the earliest journey in the previous result
            } else {
              // Add 10 more minutes to get rid the latest journey in the previous result
              this.searchParams.outBoundDateTime = moment(this.outboundJourneys[this.outboundJourneys.length - 1].departDateTime).add(10, 'minutes');
            }
          }

          if (this.searchParams.isValid.value) {
            this.searchService.search_objects[ServiceType.Rail].chosen = true;
            this.searchService.determineHighestSearchPriority();
            this.searchService.startSearches();
          }
      }

      resetSelected() {
        this.selectedOutbound = null;
        this.selectedInbound = null;
      }
      
}

LightningModalTypes.CheapestFareFinderModalComponent.component = CheapestFareFinderModalComponent;

