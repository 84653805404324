import { Component, Input } from '@angular/core';
import { RailJourneyLeg, WithSubscriptionComponent, EnterpriseSearchService, ServiceType } from '@sabstravtech/obtservices/angular'
import { OBTRailJourneyFare, OBTRailJourneyOption } from '@sabstravtech/obtservices/base';
import { uniq } from 'lodash';
@Component({
  selector: 'app-rail-basket-preview',
  templateUrl: './rail-basket-preview.component.html',
  styleUrls: ['./rail-basket-preview.component.scss']
})
export class RailBasketPreviewComponent extends WithSubscriptionComponent {
  @Input() inboundFare: OBTRailJourneyFare;
  @Input() outboundFare: OBTRailJourneyFare;
  @Input() inboundJourney: OBTRailJourneyOption;
  @Input() outboundJourney: OBTRailJourneyOption;
  @Input() sourceId: number;
  sourceName: string = '';
  constructor(
    private searchService: EnterpriseSearchService,
  ) { super() }

  ngOnInit(): void {
  }

  getRailOperators(legs: RailJourneyLeg[]): string {
    const filteredLegs = legs.filter(leg => !leg.operator.includes('Alternative service'));
    return uniq(filteredLegs.map(leg => leg.operator)).join(' - ');
  }

  async openRailTicketDetailsDialog(
    journey: OBTRailJourneyOption,
    ticket: OBTRailJourneyFare
  ) {
    if (journey && ticket) {
      const terms = ticket.terms;
      const identifiers = ticket.identifiers;
      this.searchService.searches[ServiceType.Rail].openRailTicketDetailsDialog(journey, ticket, terms, identifiers, this.sourceId.toString());
    }
  }

}
