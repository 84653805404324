<!-- <div class="journey_header">
  <ng-container *ngIf="isDateDifferent">
    <div class="mb-3 selected-date-unavailable" i18n="@@unfortunatelyThereAreNoJourneys">
      Unfortunately there are no journeys available on your selected date.
      Fares for the next avaliable journey are displayed below.
    </div>
  </ng-container>
  <h4>
    <span *ngIf="direction === RailDirection.Outbound" i18n="@@railComponetOutboundtitle">
      Outbound Journey</span
    >
    <span *ngIf="direction === RailDirection.Inbound" i18n="@@railComponetInboundtitle">
      Inbound Journey</span
    >
    -
    <span class="journey_date">{{journeys[0]?.departDateTime| sabsDate : 2}} </span>
  </h4>
  <div>
    <span i18n="@@railComponentOutboundFrom">From</span>&nbsp;
    {{ from }}
    <section (mouseleave)="tt46.close()" *ngIf="from === 'London Terminals'" class="container">
      <i
        class="ico-info"
        placement="bottom"
        triggers="manual"
        #tt46="ngbTooltip"
        (mouseenter)="tt46.open()"
        i18n-ngbTooltip
        ngbTooltip="Different station options are available on the route, please click on
                  ‘details’ for more information on a specific train."
        aria-label="Different station options are available on the route, please click on
                  ‘details’ for more information on a specific train."
        (focus)="tt46.open()"
        (blur)="tt46.close()"
        tabindex="0"
        ><span class="visuallyhidden"
          >Different station options are available on the route, please click on ‘details’ for more
          information on a specific train.</span
        ></i
      >
    </section> -->
    <!-- TODO add in message about multiple departures -->
  <!-- </div>
  <div>
    <span i18n="@@railComponentOutboundTo">To</span>&nbsp;
    {{ to }}
    <section (mouseleave)="tt46.close()" *ngIf="to === 'London Terminals'" class="container">
      <i
        class="ico-info"
        placement="bottom"
        triggers="manual"
        #tt46="ngbTooltip"
        (mouseenter)="tt46.open()"
        i18n-ngbTooltip
        ngbTooltip="Different station options are available on the route, please click on
                  ‘details’ for more information on a specific train."
        aria-label="Different station options are available on the route, please click on
                  ‘details’ for more information on a specific train."
        (focus)="tt46.open()"
        (blur)="tt46.close()"
        tabindex="0"
        ><span class="visuallyhidden"
          >Different station options are available on the route, please click on ‘details’ for more
          information on a specific train.</span
        ></i
      >
    </section> -->
    <!-- TODO add in message about multiple destinations -->
  <!-- </div>
</div>
<span class="border_line"></span>
<app-rail-results-header [departStation]="to" [results]="journeys"></app-rail-results-header>
<div class="time_button_conatiner">
  <div class="flex-row-container earlier-buttons">
    <button
      class="small-button small-button--margin previous_day"
      (click)="prevDay.emit()"
      i18n-aria-label
      id="rail-results-button-previous-day-{{
        direction === RailDirection.Outbound ? 'outbound' : 'inbound'
      }}"
      attr.aria-label="Previous Day Rail Times {{
        direction === RailDirection.Outbound ? 'outbound' : 'inbound'
      }}">
      <i class="ico-chevron-left" aria-hidden="true"></i>
      <span i18n="@@railPreviousDay">Previous Day</span>
    </button>
    <button
      class="small-button small-button--margin"
      (click)="earlier.emit(isDateDifferent)"
      i18n-aria-label
      id="rail-results-button-earlier-{{
        direction === RailDirection.Outbound ? 'outbound' : 'inbound'
      }}"
      attr.aria-label="Earlier Rail Times {{
        direction === RailDirection.Outbound ? 'outbound' : 'inbound'
      }}">
      <i class="ico-chevron-left" aria-hidden="true"></i>
      <span i18n="@@railEarlier">Earlier</span>
    </button>
  </div>
  <div class="later_rail_times_container flex-row-container flex-column-mobile">
    <button
      class="small-button small-button--margin later_rail_times"
      (click)="later.emit()"
      i18n-aria-label
      id="rail-results-button-later-{{
        direction === RailDirection.Outbound ? 'outbound' : 'inbound'
      }}"
      attr.aria-label="Later Rail Times {{
        direction === RailDirection.Outbound ? 'outbound' : 'inbound'
      }}">
      <span i18n="@@railLater">Later</span>
      <i class="ico-chevron-right" aria-hidden="true"></i>
    </button>
    <button
      class="small-button small-button--margin later_rail_times next_day"
      (click)="nextDay.emit()"
      i18n-aria-label
      id="rail-results-button-next-day-{{
        direction === RailDirection.Outbound ? 'outbound' : 'inbound'
      }}"
      attr.aria-label="Next Day Rail Times {{
        direction === RailDirection.Outbound ? 'outbound' : 'inbound'
      }}">
      <span i18n="@@railNextDay">Next Day</span>
      <i class="ico-chevron-right" aria-hidden="true"></i>
    </button>
  </div>
</div>
<span class="border_line"></span>
<span class="visuallyhidden" role="alert">{{ getHiddenText() }}</span>
<ng-container *ngFor="let ticketType of ticketTypes">
  <table class="data train_journey_table train_ticket_table">
    <ng-container *ngFor="let fareType of getTicketTypes | memoize : ticketType; let fareTypeIndex = index">
      <tr *ngIf="fareTypeIndex === 0">
        <th class="fares-header">
          {{ ticketType | translate }}
        </th>
      </tr>
      <tr
        *ngFor="
          let temp of []
            .constructor(
              expandedTickets[ticketType][fareType.key] && fareType.price ? fareType.value : 1
            )
            .fill(0);
          let index = index
        ">
        <th>
          <button
            [ngbTooltip]="
              showSplitTooltip(
                expandedTickets[ticketType][fareType.key] || fareType.value === 1,
                fareTypes,
                ticketType,
                fareType.key,
                index
              )
                ? 'To view the Splitsave journey details, please add the item to your basket.'
                : ''
            "
            (mouseleave)="tt11.close()"
            triggers="manual"
            #tt11="ngbTooltip"
            (mouseenter)="tt11.open()"
            (focus)="tt11.open()"
            (blur)="tt11.close()"
            class="ticket_details_link"
            [id]="getId(ticketType, fareTypeIndex, index)"
            (click)="
              openRailTicketDetailsDialog(
                expandedTickets[ticketType][fareType.key] || fareType.value === 1,
                fareTypes,
                ticketType,
                fareType.key,
                index
              )
            ">
            <span>
              {{ fareType.key | titlecase }}
              <i
                class="ico-info"
                *ngIf="railcardCode"
                [ngbTooltip]="railcardCode | translate"
                placement="right"></i>
            </span>
          </button>
          <div class="icon-group">
            <section
              (mouseleave)="tt7.close()"
              *ngIf="
                isEticketAvailable(
                  expandedTickets[ticketType][fareType.key] || fareType.value === 1,
                  fareTypes,
                  ticketType,
                  fareType.key,
                  index
                )
              "
              class="container">
              <img
                i18n-ngbTooltip
                ngbTooltip="This is an eTicket that may be SelfPrinted."
                alt="This is an eTicket that may be SelfPrinted."
                [src]="eTicketIcon"
                class="ico-float-left"
                tabindex="0"
                triggers="manual"
                #tt7="ngbTooltip"
                (mouseenter)="tt7.open()"
                (focus)="tt7.open()"
                (blur)="tt7.close()" />
            </section>

            <section
              (mouseleave)="tt7.close()"
              *ngIf="
                isLimitedRow(
                  expandedTickets[ticketType][fareType.key] || fareType.value === 1,
                  fareTypes,
                  ticketType,
                  fareType.key,
                  index
                )
              "
              class="container">
              <span i18n="@@railLimitedSeats">(Limited seats)</span>
            </section>
            <section
              *ngIf="
                isGroupFare(
                  expandedTickets[ticketType][fareType.key] || fareType.value === 1,
                  fareTypes,
                  ticketType,
                  fareType.key,
                  index
                )
              "
              class="container">
              <div [ngSwitch]="sourceName">
                <div *ngSwitchCase="ServiceProvider.Evolvi" (mouseleave)="tt10.close()">
                  <img
                    src="https://images.sabscorp.com/images/GroupDiscount.gif"
                    ngbTooltip="Group ticket - to qualify for this fare all travellers must travel together on the same ticket"
                    alt="Group ticket"
                    class="ico-float-left"
                    tabindex="0"
                    triggers="manual"
                    #tt10="ngbTooltip"
                    (mouseenter)="tt10.open()"
                    (focus)="tt10.open()"
                    (blur)="tt10.close()" />
                </div>
                <div *ngSwitchCase="ServiceProvider.Trainline" (mouseleave)="tt10.close()">
                  <span
                    ngbTooltip="GroupSave discount applied. All passengers must travel together"
                    class="ico-tl-group-save"
                    triggers="manual"
                    #tt10="ngbTooltip"
                    (mouseenter)="tt10.open()"
                    (focus)="tt10.open()"
                    (blur)="tt10.close()"
                    >GroupSave</span
                  >
                </div>
              </div>
            </section>
          </div> -->

          <!-- <button (click)="log(fareTypes[ticketType])">
                        {{fareType.value}}
                    </button> -->
          <!-- <div class="flex-row-container train-icons-container">
            <section
              *ngIf="
                isTrainlineSplitFare(
                  expandedTickets[ticketType][fareType.key] || fareType.value === 1,
                  fareTypes,
                  ticketType,
                  fareType.key,
                  index
                )
              "
              class="container">
              <span>
                <img
                  class="split-ticket-logo"
                  src="https://images.sabscorp.com/images/enterprise/lightUK/assets/png/split-ticket.png"
                  alt="Split Tickets"
              /></span>
            </section>
            <i
              *ngIf="
                !expandedTickets[ticketType][fareType.key] &&
                fareType.value > 1 &&
                fareType.price &&
                index === 0
              "
              (click)="expandedTickets[ticketType][fareType.key] = true"
              container="body"
              placement="top"
              tabindex="0"
              role="button"
              triggers="manual"
              class="ico-float-right ico-add"
              attr.aria-label="+ Show other tickets for {{ fareType.key | titlecase }} {{
                direction === RailDirection.Outbound ? 'outbound' : 'inbound'
              }}"
              i18n-aria-label
              aria-expanded="false"
              ><span i18n="@@railResultsBlockShowOther" class="visuallyhidden"
                >Show other tickets</span
              ></i
            >
            <i
              *ngIf="
                expandedTickets[ticketType][fareType.key] &&
                fareType.value > 1 &&
                fareType.price &&
                index === 0
              "
              (click)="expandedTickets[ticketType][fareType.key] = false"
              container="body"
              placement="top"
              tabindex="0"
              role="button"
              triggers="manual"
              class="ico-float-right ico-minus"
              attr.aria-label="- Hide other tickets for {{ fareType.key | titlecase }} {{
                direction === RailDirection.Outbound ? 'outbound' : 'inbound'
              }}"
              aria-expanded="true"
              i18n-aria-label
              ><span i18n="@@railResultsBlockHideOther" class="visuallyhidden"
                >Hide other tickets</span
              ></i
            >
          </div>
        </th>
        <td *ngFor="let journey of journeys">
          <ng-container
            *ngIf="
              fieldFromObject(
                journey,
                expandedTickets[ticketType][fareType.key] || fareType.value === 1,
                fareTypes,
                ticketType,
                fareType.key,
                index
              ) || {} as ticket
            "> -->
            <!-- [disabled]="!ticket.selectable" -->
            <!-- {{log(direction,ticket, journey, ticketType, fareType.key)}} -->
            <!-- <ng-container
              [ngTemplateOutlet]="
                ticket.selectable && (!ticket.unavailable || canOverride) ? button : disabled_button
              "
              [ngTemplateOutletContext]="{ ticket, journey, ticketType }"></ng-container>
            <div class="white-theme">
              <app-preferred-icons
                [tmcPreferred]="ticket.tmcPreferred"
                [tmcPreferredLogoUrl]="ticket.tmcPreferredLogoUrl"
                [tmcPreferredMessage]="ticket.tmcPreferredMessage"
                [clientPreferred]="ticket.officePreferred || ticket.companyPreferred"
                [companyPreferredMessage]="ticket.companyPreferredMessage"
                [officePreferredMessage]="ticket.officePreferredMessage">
              </app-preferred-icons>
            </div>

            <app-warning-tags [iconId]="fareTypeIndex" [ticket]="ticket"></app-warning-tags>
          </ng-container>
        </td>
      </tr>
    </ng-container>
  </table>
</ng-container> -->

<ng-template #button let-ticket="ticket" let-journey="journey" let-ticketType="ticketType">
  <ng-template #tooltipTemplate>
    <ng-container *ngIf="ticket.selectable">
      <div>{{ticket.type?.replace(',',', ')}}</div>
      <div>
        <a (click)="openRailTicketDetailsDialog(journey, ticket)" i18n="@@railViewConditions" class="view_conditions">View Conditions</a>
      </div>
    </ng-container>
    <ng-container *ngIf="!ticket.selectable">
      <span i18n="@@railresultsUnavailable">Unavailable</span>
    </ng-container>
  </ng-template>
  <div class="other-icons-top">
    <div class="white-theme">
      <app-preferred-icons
        [tmcPreferred]="ticket.tmcPreferred"
        [tmcPreferredLogoUrl]="ticket.tmcPreferredLogoUrl"
        [tmcPreferredMessage]="ticket.tmcPreferredMessage"
        [clientPreferred]="ticket.officePreferred || ticket.companyPreferred"
        [companyPreferredMessage]="ticket.companyPreferredMessage"
        [officePreferredMessage]="ticket.officePreferredMessage">
      </app-preferred-icons>
    </div>
    <div class="available-seats" *ngIf="ticket.availableSeats && ticket.availableSeats < 9">
      {{ ticket.availableSeats }} <span i18n="@@railSeatsLeft">Seats Left</span>
    </div>
    <section
      *ngIf="ticket.identifiers?.isSplit"
      class="container">
      <span>
        <img
          class="split-ticket-logo"
          src="https://images.sabscorp.com/images/enterprise/lightUK/assets/png/split-ticket.png"
          alt="Split Tickets"
      /></span>
    </section>
  </div>
  <div class="td_button_container">
    <section class="container" (mouseleave)="buttonTt.close()">
      <button
        [ngbTooltip]="tooltipTemplate"
        tooltipClass="button_tooltip"
        triggers="manual"
        #buttonTt="ngbTooltip"
        (focus)="buttonTt.open()"
        (blur)="buttonTt.close()"
        (mouseenter)="buttonTt.open()"
        [ngClass]="[
          ticket.selected ? 'selected-price' : 'notselected',
          direction + '-' + ticket.price,
          (ticketType === RailFareTypes.returnJourneyFares ||
            ticketType === RailFareTypes.splitFares) &&
          searchParams.selectedTicket &&
          direction === RailDirection.Inbound
            ? 'selectable_return'
            : '',
          ticketType === RailFareTypes.splitFares ? 'split_border' : ''
        ]"
        class="rail-price"
        id="{{ !!ticket.price ? direction + '-' + ticket.price : direction + '-' + 'No ticket' }}"
        (click)="onSelectTicket(journey, ticket, ticketType)"
        mat-button
        color="accent"
        attr.aria-label="{{
          (searchParams.chosenSearchType === RailSearchJourneyType.ReturnJourney &&
          this.direction === RailDirection.Inbound &&
          !searchParams.chosenSearchType.selectedTicket
            ? '-'
            : '') +
            direction ===
          RailDirection.Outbound
            ? 'Outbound'
            : 'Inbound'
        }}
        {{ ticket.price | currency : ticket.currency }} {{ ticket.type | titlecase }}{{
          ticket.selected ? ' selected' : ''
        }}. Departing {{ journey.from }} at
        {{ journey.departDateTime | sabsTimePipe }} arriving at {{ journey.to }} at {{
          journey.arriveDateTime | sabsTimePipe
        }}"
        [attr.aria-pressed]="ticket.selected"
        aria-live="polite">
        <ng-container
          *ngIf="
            ticketType !== RailFareTypes.returnJourneyFares ||
            !searchParams.selectedTicket ||
            direction !== RailDirection.Inbound
          ">
          {{
            ticket.price === undefined
              ? ''
              : direction === RailDirection.Inbound &&
                (ticketType === RailFareTypes.returnJourneyFares ||
                  ticketType === RailFareTypes.splitFares)
              ? '-'
              : (ticket.price | currency : ticket.currency || '')
          }}
        </ng-container>
        <ng-container
          *ngIf="
            (ticketType === RailFareTypes.returnJourneyFares ||
              ticketType === RailFareTypes.splitFares) &&
            searchParams.selectedTicket &&
            direction === RailDirection.Inbound
          ">
          <div class="basket">
            <span class="counter">+</span>
            <img
              class="cart"
              [ngClass]="{ split_icon: ticketType === RailFareTypes.splitFares }"
              src="https://images.sabscorp.com/images/enterprise/lightUK/assets/images/empty-cart.svg"
              alt="Cart" />
          </div>
        </ng-container>
      </button>
    </section>
    <section class="container" (mouseleave)="expandTt.close()">
      <button class="more_tickets_button" 
              ngbTooltip="Expand for more fares"
              triggers="manual"
              #expandTt="ngbTooltip"
              i18n-ngbTooltip
              (focus)="expandTt.open()"
              (blur)="expandTt.close()"
              (mouseenter)="expandTt.open()"
              (click)="openTicketsOfTheSameType(journey, ticket)" 
              [hidden]="direction === RailDirection.Inbound &&
              (ticketType === RailFareTypes.returnJourneyFares ||
              ticketType === RailFareTypes.splitFares)"
              attr.aria-label="Open more ticket types for {{ ticket.type | titlecase }}{{
                ticket.selected ? ' selected' : ''
              }}. Departing {{ journey.from }} at
              {{ journey.departDateTime | sabsTimePipe }} arriving at {{ journey.to }} at {{
                journey.arriveDateTime | sabsTimePipe
              }}">
        +
      </button>
    </section>
  </div>
  <div class="other-icons-container">
    <app-warning-tags [iconId]="ticket.fareHash" [ticket]="ticket"></app-warning-tags>
  </div>
</ng-template>

<ng-template #disabled_button let-ticket="ticket" let-journey="journey" let-ticketType="ticketType">
  <div class="other-icons-top">
    <div class="white-theme">
      <app-preferred-icons
        [tmcPreferred]="ticket.tmcPreferred"
        [tmcPreferredLogoUrl]="ticket.tmcPreferredLogoUrl"
        [tmcPreferredMessage]="ticket.tmcPreferredMessage"
        [clientPreferred]="ticket.officePreferred || ticket.companyPreferred"
        [companyPreferredMessage]="ticket.companyPreferredMessage"
        [officePreferredMessage]="ticket.officePreferredMessage">
      </app-preferred-icons>
    </div>
    <section
      *ngIf="ticket.identifiers?.isSplit"
      class="container">
      <span>
        <img
          class="split-ticket-logo"
          src="https://images.sabscorp.com/images/enterprise/lightUK/assets/png/split-ticket.png"
          alt="Split Tickets"
      /></span>
    </section>
  </div>
  <div class="td_button_container">
    <span
      [ngClass]="[ticket.selected ? 'selected-price' : 'notselected', direction + '-' + ticket.price]"
      aria-hidden="true"
      class="rail-price rail-no"
      id="{{ !!ticket.price ? direction + '-' + ticket.price : direction + '-no-ticket' }}"
      [ngbTooltip]="ticket.unavailable ? 'Fare is unavailable due to being out of policy' : 'Unavailable'"
      i18n-ngbTooltip>
      {{
        ticket.price === undefined
          ? ''
          : direction === RailDirection.Inbound &&
            (ticketType === RailFareTypes.returnJourneyFares ||
              ticketType === RailFareTypes.splitFares)
          ? '-'
          : (ticket.price | currency : ticket.currency || '')
      }}
    </span>
    <section class="container" (mouseleave)="expandTt.close()">
      <button class="more_tickets_button" 
              ngbTooltip="Expand for more fares"
              triggers="manual"
              #expandTt="ngbTooltip"
              i18n-ngbTooltip
              (focus)="expandTt.open()"
              (blur)="expandTt.close()"
              (mouseenter)="expandTt.open()"
              (click)="openTicketsOfTheSameType(journey, ticket)" 
              [hidden]="direction === RailDirection.Inbound &&
              (ticketType === RailFareTypes.returnJourneyFares ||
              ticketType === RailFareTypes.splitFares)"
              attr.aria-label="Open more ticket types for {{ ticket.type | titlecase }}{{
                ticket.selected ? ' selected' : ''
              }}. Departing {{ journey.from }} at
              {{ journey.departDateTime | sabsTimePipe }} arriving at {{ journey.to }} at {{
                journey.arriveDateTime | sabsTimePipe
              }}">
        +
      </button>
    </section>
  </div>
  <div class="other-icons-container">
    <app-warning-tags [iconId]="ticket.fareHash" [ticket]="ticket"></app-warning-tags>
  </div>
</ng-template>


<table class="rail_results_table">
  <tr class="earlier_row">
    <td colspan="5">
      <button
        class="small-button small-button--margin"
        (click)="earlier.emit(isDateDifferent)"
        i18n-aria-label
        id="rail-results-button-earlier-{{
          direction === RailDirection.Outbound ? 'outbound' : 'inbound'
        }}"
        attr.aria-label="Earlier Rail Times {{
          direction === RailDirection.Outbound ? 'outbound' : 'inbound'
        }}">
        <span i18n="@@railEarlier">Earlier</span>
        <i class="ico-chevron-up" aria-hidden="true"></i>
      </button>
    </td>
    <td colspan="4">
      <div class="flex-row-container flex-column-mobile time_container_top">
        <button
          class="small-button small-button--margin previous_day"
          (click)="prevDay.emit()"
          i18n-aria-label
          id="rail-results-button-previous-day-{{
            direction === RailDirection.Outbound ? 'outbound' : 'inbound'
          }}"
          attr.aria-label="Previous Day Rail Times {{
            direction === RailDirection.Outbound ? 'outbound' : 'inbound'
          }}">
          <i class="ico-chevron-left" aria-hidden="true"></i>
          <span i18n="@@railPreviousDay">Previous Day</span>
        </button>
        <span >{{journeys[0]?.departDateTime| sabsDate : 2}} </span>
        <button
          class="small-button small-button--margin later_rail_times next_day"
          (click)="nextDay.emit()"
          i18n-aria-label
          id="rail-results-button-next-day-{{
            direction === RailDirection.Outbound ? 'outbound' : 'inbound'
          }}"
          attr.aria-label="Next Day Rail Times {{
            direction === RailDirection.Outbound ? 'outbound' : 'inbound'
          }}">
          <span i18n="@@railNextDay">Next Day</span>
          <i class="ico-chevron-right" aria-hidden="true"></i>
        </button>
      </div>
    </td>
  </tr>
  <tr class="header_row">
    <!--Headers-->
    <th>
      <span class="visuallyhidden">Is this train busy?</span>
    </th>
    <th i18n="@@railDepart" class="pc_display">
      Depart
    </th>
    <th i18n="@@railArrive" class="pc_display">
      Arrive
    </th>
    <th i18n="@@railDuration" class="pc_display">
      Duration
    </th>
    <th class="mobile_display" i18n="@@railJourney">
      Journey
    </th>
    <th i18n="@@railDetails">
      Details
    </th>
    <th i18n="@@railStandardSingles" *ngIf="displayStandardSingles">
      Standard Singles
    </th>
    <th i18n="@@railFirstClassSingles" *ngIf="displayFirstClassSingles">
      1st Class Singles
    </th>
    <th i18n="@@railStandardReturns" *ngIf="displayStandardReturns">
      Standard Returns
    </th>
    <th i18n="@@railFirstClassReturns" *ngIf="displayFirstClassReturns">
      1st Class Returns
    </th>
  </tr>
  <tbody>
    <ng-container *ngFor="let journey of journeys">
      <tr [ngClass]="{ selected_row: newResultsObject.standardSingles[journey.journeyHash]?.selected ||
        newResultsObject.standardReturns[journey.journeyHash]?.selected ||
        newResultsObject.firstClassSingles[journey.journeyHash]?.selected ||
        newResultsObject.firstClassSingles[journey.journeyHash]?.selected,
        'cancelled_text': journey.cancelled }">
        <th>
          <div class="flex-column-container">
            <section (mouseleave)="ttb.close()" class="container" *ngIf="busyTimes[journey.journeyHash]">
              <img src="https://images.sabscorp.com/images/enterprise/lightUK/assets/png/busy_train.png" 
                  alt="This service is predicted to be busy."
                  class="busy_train"
                  #ttb="ngbTooltip"
                  (mouseenter)="ttb.open()"
                  triggers="manual"
                  (focus)="ttb.open()"
                  (blur)="ttb.close()"
                  tabindex="0"
                  ngbTooltip="This service is predicted to be busy."
                  i18n-ngbTooltip>
            </section>
            <section (mouseleave)="ttbus.close()" class="container" *ngIf="checkForBus(journey)">
              <i  class="ico-bus replacement_bus"
                  alt="This is a bus replacement service."
                  #ttbus="ngbTooltip"
                  (mouseenter)="ttbus.open()"
                  triggers="manual"
                  (focus)="ttbus.open()"
                  (blur)="ttbus.close()"
                  tabindex="0"
                  ngbTooltip="This is a bus replacement service."
                  i18n-ngbTooltip></i>
            </section>
          </div>
        </th>
        <th  class="pc_display">
          <div>
            {{getDepartCode(journey)}}
          </div>
          <div>
            {{journey.departDateTime | sabsTimePipe}}
            <div *ngIf="datesAfterInitial[i]" class="plus_one" i18n-ngbTooltip ngbTooltip="Departure date is next day.">
              +1
            </div>
            <div>
              <span i18n="@@railCo2Kg" class="co2_span">CO<sub>2</sub> Emissions(kg)</span>
            </div>
          </div>
        </th>
        <th  class="pc_display">
          <div>
            {{getArriveCode(journey)}}
          </div>
          <div>
            {{journey.arriveDateTime | sabsTimePipe}}
          </div>
          <div>
            <span class="co2_span">
              {{journey.co2PerPassenger || 'n/a'}}
            </span>
          </div>
        </th>
        <th class="pc_display">
          {{journeyTime | memoize:journey.departDateTime:journey.arriveDateTime}}
          <span *ngIf="journey.overtaken"
                class="overtaken"
                i18n="@@railresultsheaderOvertaken">Overtaken </span>
          <span *ngIf="journey.cancelled"
                class="cancelled"
                i18n="@@railresultsheaderCancelled">Service Cancelled
          </span>
        </th>
        <th class="mobile_display">
          <div>
            {{getDepartCode(journey)}}
          </div>
          <div>
            {{journey.departDateTime | sabsTimePipe}}
            <div *ngIf="datesAfterInitial[i]" class="plus_one" i18n-ngbTooltip ngbTooltip="Departure date is next day.">
              +1
            </div>
          </div>
          <div>
            {{getArriveCode(journey)}}
          </div>
          <div>
            {{journey.arriveDateTime | sabsTimePipe}}
          </div>
          <div>
            <span i18n="@@railCo2Kg" class="co2_span">CO<sub>2</sub> Emissions(kg)</span>
          </div>
          <div>
            <span class="co2_span">
              {{journey.co2PerPassenger || 'n/a'}}
            </span>
          </div>
          <div>
            {{journeyTime | memoize:journey.departDateTime:journey.arriveDateTime}}
          </div>
          <span *ngIf="journey.overtaken"
                class="overtaken"
                i18n="@@railresultsheaderOvertaken">Overtaken </span>
          <span *ngIf="journey.cancelled"
                class="cancelled"
                i18n="@@railresultsheaderCancelled">Service Cancelled
          </span>
        </th>
        <th>
          <button class="details_button" (click)="openDetailsModal(journey)">
            <i class="currency_info_icon"></i>
          </button>
          <div class="changes_text">
            {{journey.changes}} changes
          </div>
        </th>
        <td *ngIf="displayStandardSingles">
          <ng-container *ngIf="newResultsObject.standardSingles[journey.journeyHash] as ticket">
            <ng-container
              [ngTemplateOutlet]="
                ticket.selectable && (!ticket.unavailable || canOverride) ? button : disabled_button
              "
              [ngTemplateOutletContext]="{ ticket, journey, ticketType: getTicketType(journey, ticket) }"></ng-container>
          </ng-container>
        </td>
        <td *ngIf="displayFirstClassSingles">
          <ng-container *ngIf="newResultsObject.firstClassSingles[journey.journeyHash] as ticket">
            <ng-container
              [ngTemplateOutlet]="
                ticket.selectable && (!ticket.unavailable || canOverride) ? button : disabled_button
              "
              [ngTemplateOutletContext]="{ ticket, journey, ticketType: getTicketType(journey, ticket) }"></ng-container>
          </ng-container>
        </td>
        <td *ngIf="displayStandardReturns">
          <ng-container *ngIf="newResultsObject.standardReturns[journey.journeyHash] as ticket">
            <ng-container
              [ngTemplateOutlet]="
                ticket.selectable && (!ticket.unavailable || canOverride) ? button : disabled_button
              "
              [ngTemplateOutletContext]="{ ticket, journey, ticketType: getTicketType(journey, ticket) }"></ng-container>
          </ng-container>
        </td>
        <td *ngIf="displayFirstClassReturns">
          <ng-container *ngIf="newResultsObject.firstClassReturns[journey.journeyHash] as ticket">
            <ng-container
              [ngTemplateOutlet]="
                ticket.selectable && (!ticket.unavailable || canOverride) ? button : disabled_button
              "
              [ngTemplateOutletContext]="{ ticket, journey, ticketType: getTicketType(journey, ticket) }"></ng-container>
          </ng-container>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tr class="later_row">
    <td colspan="5">
      <button
        class="small-button small-button--margin later_rail_times"
        (click)="later.emit()"
        i18n-aria-label
        id="rail-results-button-later-{{
          direction === RailDirection.Outbound ? 'outbound' : 'inbound'
        }}"
        attr.aria-label="Later Rail Times {{
          direction === RailDirection.Outbound ? 'outbound' : 'inbound'
        }}">
        <span i18n="@@railLater">Later</span>
        <i class="ico-chevron-down" aria-hidden="true"></i>
      </button>
    </td>
  </tr>
</table>