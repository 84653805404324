import { Component, Input } from '@angular/core';
import {
  includedExpression,
  excludedExpression
} from '@sabstravtech/obtservices/base';
@Component({
  selector: 'app-breakfast-icon',
  templateUrl: './breakfast-icon.component.html',
  styleUrl: './breakfast-icon.component.scss'
})

export class BreakfastIconComponent {
  @Input() room: any;

  hasBreakfast(description: string, roomDescription: string, roomGeneralPolicy: string): boolean {
    return (
      (description.match(includedExpression) && !description.match(excludedExpression)) ||
      (roomDescription?.match(includedExpression) && !roomDescription?.match(excludedExpression)) ||
      (roomGeneralPolicy?.match(includedExpression) && !roomGeneralPolicy?.match(excludedExpression))
    );
  }

}
